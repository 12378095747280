import { KEY_TOKEN, PASSIO_MAIN_DOMAIN, TYPE_BRAND } from '@constants/system';
import { TYPE_ACCOUNT_AGENCY, TYPE_ACCOUNT_HOST } from '@constants/profile';
import { LOGIN_HOST_ROUTES } from '@features/login-host/config/route';
import { LOGIN_ROUTES } from '@features/login/config/route';
import axios from 'axios';
import Cookies from 'js-cookie';
import * as process from 'process';
import { LOGIN_AGENCY_ROUTES } from '@features/login-agency/config/route';

export const setupAxios = (isAuth: boolean) => {
  axios.interceptors.request.use(
    function (config) {
      if (isAuth) {
        const authToken = Cookies.get(KEY_TOKEN);
        if (authToken) {
          config.headers.Authorization = `Bearer ${authToken}`;
        }
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      if (error.response?.status === 401) {
        Cookies.remove(TYPE_BRAND);
        Cookies.remove(KEY_TOKEN, {
          path: '/',
          domain: PASSIO_MAIN_DOMAIN,
        });
        const routerLogin = Cookies.get(TYPE_BRAND) ? 
        Number(Cookies.get(TYPE_BRAND)) === TYPE_ACCOUNT_HOST ? LOGIN_HOST_ROUTES.index : 
        Number(Cookies.get(TYPE_BRAND)) === TYPE_ACCOUNT_AGENCY ? LOGIN_AGENCY_ROUTES.index :
        LOGIN_ROUTES.index : LOGIN_ROUTES.index;
        window.location.href = routerLogin;
      }
      return Promise.reject(error);
    },
  );

  return axios;
};
export const setupAxiosFormData = (isAuth: boolean) => {
  let instance;
  if (isAuth) {
    const authToken = Cookies.get(KEY_TOKEN);
    instance = axios.create({
      baseURL: process.env.BRAND_SAAS_DOMAIN_URL,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });
  } else instance = axios;

  instance.interceptors.request.use(
    function (config) {
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      if (error.response?.status === 401) {
        Cookies.remove(TYPE_BRAND);
        Cookies.remove(KEY_TOKEN, {
          path: '/',
          domain: PASSIO_MAIN_DOMAIN,
        });
        const routerLogin = Cookies.get(TYPE_BRAND) ?
        Number(Cookies.get(TYPE_BRAND)) === TYPE_ACCOUNT_HOST ? LOGIN_HOST_ROUTES.index :
        Number(Cookies.get(TYPE_BRAND)) === TYPE_ACCOUNT_AGENCY ? LOGIN_AGENCY_ROUTES.index :
        LOGIN_ROUTES.index : LOGIN_ROUTES.index;
        window.location.href = routerLogin;
      }
      return Promise.reject(error);
    },
  );

  return instance;
};
