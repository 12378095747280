export const KEY_FETCH_PROFILE = 'fetchProfile';
export const KEY_FETCH_LOCALE = 'fetchLocale';

export const KEY_FETCH_PROFILE_HOST = 'fetchProfileHost';
export const KEY_FETCH_PROFILE_AGENCY = 'fetchProfileAgency';

export const TYPE_ACCOUNT_HOST = 0;
export const TYPE_ACCOUNT_BRAND = 1;
export const TYPE_ACCOUNT_SELLER = 2;
export const TYPE_ACCOUNT_AGENCY = 3;
export const TYPE_ACCOUNT_BRAND_RHOTO = 4;