const  vi =  {
 translation: {
  "BS_CLICK": "Click",
  "BS_CPO": "CPO",
  "BS_CVR": "CVR",
  "BS_ITEM_PER_ORDER": "Item per order",
  "BS_NO": "No",
  "BS_TOTAL_ORDER": "Tổng đơn hàng",
  "BS_UNIQUE_CLICK": "Unique click",
  "billion": "Tỷ",
  "bs_1_step_left": "Còn 1 bước nữa thôi",
  "bs_ICT": "ICT",
  "bs_KOC": "KOC ({{total}})",
  "bs_about_me_title_1": "We proud to be one of the top 5 Tiktok Multi-Channel Networks (MCN) and strategic partner of the largest e-commerce marketplace in Southeast Asia.",
  "bs_about_me_title_2": "We possess more than 2000+ exclusive influencers and content creators and a community of more than 500K content creators from multi-niche, cross 6 markets.",
  "bs_about_me_title_3": "Time-efficiently with the always-on campaign. We possess campaign launching automation. Replacing manual process with payment workflow efficiently.",
  "bs_about_us": "About us",
  "bs_action_label_report_detail": " Báo cáo chi tiết",
  "bs_action_label_video_list": "Danh sách video",
  "bs_action_view_suggest_creator": "Danh Sách Yêu Cầu",
  "bs_actions_crawl_data": "Crawl Data",
  "bs_active": "Đang hoạt động",
  "bs_active_status": "Active",
  "bs_add_item": "Thêm",
  "bs_add_key_test": "Key test VN",
  "bs_add_new_link": "Thêm liên kết mới",
  "bs_add_shop": "Thêm cửa hàng",
  "bs_add_to_cart": "Thêm vào giỏ hàng",
  "bs_add_to_kol_list": "Thêm vào danh sách KOL",
  "bs_added_cart": "Đã thêm",
  "bs_advertiser": "Advertiser",
  "bs_age": "Tuổi",
  "bs_all_brand": "Select all Brand",
  "bs_all_sku": "Select all SKU",
  "bs_allow_all_sku": "Áp dụng tất cả SKU",
  "bs_allow_all_sub_brands": "Áp dụng tất cả Sub-brand",
  "bs_already_have_account": "Bạn đã có tài khoản",
  "bs_aov": "AOV",
  "bs_aov_tooltip_content": "Average order value = GMV Actual / Total Order",
  "bs_approve": "Phê duyệt",
  "bs_as": "As",
  "bs_beauty": "Làm đẹp & Mỹ phẩm",
  "bs_billion": "Tỷ",
  "bs_blog": "Blog",
  "bs_booking_camp_status_off": "Đã tắt",
  "bs_booking_camp_status_on": "Đang bật",
  "bs_brand_affiliate_actual_commission": "Hoa hồng thực tế",
  "bs_brand_affiliate_booking_cost": "Chi phí booking",
  "bs_brand_affiliate_comment": "Lượt Bình Luận",
  "bs_brand_affiliate_commission": "Hoa hồng ước tính",
  "bs_brand_affiliate_commission_cost": "Chi phí hoa hồng",
  "bs_brand_affiliate_compare_last_period": "so với kỳ trước",
  "bs_brand_affiliate_content_video_tiktok": "Nội dung video",
  "bs_brand_affiliate_cps_report": "Tổng quan",
  "bs_brand_affiliate_cps_report_text": "Kết nối cửa hàng và thiết lập KPI để hoàn thành báo cáo",
  "bs_brand_affiliate_dashboard_title": "Brand Afiliate",
  "bs_brand_affiliate_engagement": "Lượt Tương Tác",
  "bs_brand_affiliate_estimate_total_cost": "Tổng chi phí dự kiến",
  "bs_brand_affiliate_gmv": "GMV",
  "bs_brand_affiliate_item": "Item",
  "bs_brand_affiliate_label_last_update": "Lần cập nhật gần nhất",
  "bs_brand_affiliate_label_list_live": "Danh sách live",
  "bs_brand_affiliate_label_live_hour_by_day": "Số giờ live qua từng ngày",
  "bs_brand_affiliate_label_main_index": "Các chỉ số chính",
  "bs_brand_affiliate_label_ranking": "Bảng xếp hạng",
  "bs_brand_affiliate_label_time_view_data": "Thời gian",
  "bs_brand_affiliate_label_top_5_duration": "Top 5 Chăm Chỉ",
  "bs_brand_affiliate_label_top_5_expert": "Top 5 Chuyên Gia Livestream",
  "bs_brand_affiliate_label_top_5_kol": "BXH nhà sáng tạo",
  "bs_brand_affiliate_label_top_5_order": "Top 5 Chốt Đơn",
  "bs_brand_affiliate_label_top_5_product": "BXH sản phẩm",
  "bs_brand_affiliate_label_top_5_revenue": "Top 5 Doanh Số",
  "bs_brand_affiliate_label_top_5_sub_brand": "BXH dòng sản phẩm",
  "bs_brand_affiliate_label_top_live_stream": "BXH phiên live",
  "bs_brand_affiliate_label_top_platform": "BXH nền tảng",
  "bs_brand_affiliate_label_trend_chart_each_index": "Biểu đồ xu hướng mỗi chỉ số",
  "bs_brand_affiliate_label_view_all": "Xem tất cả",
  "bs_brand_affiliate_like": "Lượt Thích",
  "bs_brand_affiliate_live_stream": "Tổng phiên live",
  "bs_brand_affiliate_new_user": "Người mua mới",
  "bs_brand_affiliate_option_last_1_month": "Tháng trước",
  "bs_brand_affiliate_option_last_1_quarter": "Quý trước",
  "bs_brand_affiliate_option_last_7_days": "7 ngày gần nhất",
  "bs_brand_affiliate_option_last_week": "Tuần trước",
  "bs_brand_affiliate_option_this_month": "Tháng này",
  "bs_brand_affiliate_option_this_quarter": "Quý này",
  "bs_brand_affiliate_option_this_week": "Tuần này",
  "bs_brand_affiliate_option_today": "Hôm nay",
  "bs_brand_affiliate_option_yesterday": "Hôm qua",
  "bs_brand_affiliate_order": "Đơn hàng",
  "bs_brand_affiliate_post": "Số Bài Đăng",
  "bs_brand_affiliate_product": "Sản phẩm đã bán",
  "bs_brand_affiliate_roi": "ROI",
  "bs_brand_affiliate_save": "Lượt Lưu",
  "bs_brand_affiliate_share": "Lượt Chia Sẻ",
  "bs_brand_affiliate_total_host": "Tổng host",
  "bs_brand_affiliate_user": "Tất cả người mua",
  "bs_brand_connected": "Nhãn hàng đang liên kết",
  "bs_brand_insights": "Brand Detail",
  "bs_brand_logo": "Logo thương hiệu",
  "bs_brand_logo_required": "Vui lòng nhập logo thương hiệu",
  "bs_brand_name": "Tên thương hiệu",
  "bs_brand_name_placeholder": "Nhập tên thương hiệu",
  "bs_brands": "Brands",
  "bs_brands_booking": "Brands Booking",
  "bs_brands_booking_listing": "Danh sách chiến dịch",
  "bs_budget": "Ngân sách",
  "bs_button_add_product_sample": "Bật sản phẩm mẫu",
  "bs_button_apply": "Áp dụng",
  "bs_button_content_write_review": "Viết nhận xét",
  "bs_button_continue": "Tiếp tục",
  "bs_button_copy_manual": "Sao chép hướng dẫn",
  "bs_button_create_host": "Tạo host",
  "bs_button_create_request": "Tạo Yêu Cầu",
  "bs_button_create_room": "Tạo phòng live",
  "bs_button_deselect_all": "Bỏ chọn tất cả",
  "bs_button_export_data": "Export Data",
  "bs_button_finish": "Kết thúc",
  "bs_button_import_sale_performance": "Import Data",
  "bs_button_ok_popup_confirm_save_kol": "Đồng ý",
  "bs_button_reset": "Đặt lại",
  "bs_button_search": "Tìm kiếm",
  "bs_button_skip": "Bỏ qua",
  "bs_button_start": "Bắt đầu",
  "bs_button_start_discovery": "Bắt đầu khám phá",
  "bs_button_submit": "Hoàn thành",
  "bs_button_sync_orders": "Cập nhật đơn hàng",
  "bs_button_try_again": "Thử lại",
  "bs_buy_video_now": "Mua gói video ngay",
  "bs_calendar_host_register": "Lịch host đăng ký",
  "bs_calendar_live_hour_kol": "Số giờ live KOL",
  "bs_calendar_scheduled": "Lịch đã xếp",
  "bs_campaign": "Chiến dịch",
  "bs_campaign_error_platform_link": "Link submit không hợp lệ",
  "bs_campaign_error_type__channel_link": "Link channel không đúng định dạng",
  "bs_campaign_error_type_link": "Link submit không đúng định dạng",
  "bs_campaign_info": "Thông tin chiến dịch",
  "bs_campaign_list_title": "Danh sách",
  "bs_campaign_name": "Tên chiến dịch",
  "bs_campaign_name_is_required": "Tên campaign không được bỏ trống",
  "bs_campaign_name_placeholder": "Nhập tên campaign",
  "bs_campaign_report": "Báo cáo chiến dịch",
  "bs_campaign_report_total_koc_joined": "KOC tham gia",
  "bs_campaign_report_unit_engagements": "Lượt tương tác",
  "bs_campaign_report_unit_posts": "Bài đăng",
  "bs_campaign_report_unit_views": "Lượt xem",
  "bs_campaign_reports": "Báo Cáo Chiến Dịch",
  "bs_campaign_search_status_title": "Trạng thái",
  "bs_campaign_search_time_title": "Thời gian",
  "bs_campaign_search_title": "Chiến dịch",
  "bs_campaign_send_invitation_successfully": "Gửi lời mời thành công",
  "bs_campaign_send_mail_successfully": "Đã gửi email thành công",
  "bs_campaign_title_egagement": "Egagement",
  "bs_campaign_title_perfomence_count": "Performance",
  "bs_campaign_title_sale_performence": "Sales Performance",
  "bs_campaigns": "Chiến dịch",
  "bs_campaigns_overview": "Tổng Quan Chiến Dịch",
  "bs_cancel": "Hủy",
  "bs_cancel_edit": "Hủy chỉnh sửa",
  "bs_cancel_list_footer": "Quay lại",
  "bs_cancel_list_kol": "Hủy",
  "bs_cancel_live": "Hủy lịch live",
  "bs_cancel_popup_confirm": "Hủy bỏ",
  "bs_cancel_session": "Hủy phiên live",
  "bs_canceled": "Đã hủy",
  "bs_case_study": "Case Study",
  "bs_categories": "Categories",
  "bs_category": "Category",
  "bs_change_live_time": "Đổi giờ live",
  "bs_change_live_time_failed": "Thay đổi lịch live thất bại",
  "bs_change_live_time_failed_pending_request": "Không thể tạo yêu cầu mới khi còn yêu cầu đang chờ xử lý",
  "bs_change_live_time_failed_same_time": "Đã có phiên live vào thời gian này",
  "bs_change_password": "Thay đổi mật khẩu",
  "bs_checkin_failed": "Check-in thất bại",
  "bs_checkin_image": "Tải ảnh phiên live",
  "bs_choice_live_session": "Chọn phiên live",
  "bs_choose_jobs": "Chọn ngành nghề",
  "bs_choose_jobs_placeholder": "-Chọn ngành nghề-",
  "bs_click_total": "Tổng Số Click",
  "bs_clicks": "Clicks",
  "bs_close": "Close",
  "bs_collapse": "Thu gọn",
  "bs_column_total_content": "Số lượng Live/Video",
  "bs_commission": "Hoa hồng",
  "bs_commission_actual": "Commission Actual",
  "bs_comprehensive_tracking_content": "Transparent and detailed measurement system to track & optimize performance",
  "bs_comprehensive_tracking_description": "Transparent, detailed and real-time measurement system helps you see how your campaigns perform across any channels – KOLs/ Creators – Websites… ",
  "bs_comprehensive_tracking_title": "Comprehensive Tracking",
  "bs_comprehensive_tracking_title_1": "End-to-end Performance Monitor (Order/ Sales/ Comission...)",
  "bs_comprehensive_tracking_title_2": "Tracking Traffic (Campaign/ Source/ KOL...)",
  "bs_comprehensive_tracking_title_3": "Real-time & Transparent Data (Data Export/ Data Dashboard For Website Or Store Online...)",
  "bs_confirm": "Xác nhận",
  "bs_confirm_cancel_campaign": "Bạn có chắc chắn hủy campaign không?",
  "bs_confirm_cancel_list_kol": "Bạn có chắc chắn hủy chọn danh sách KOL không?",
  "bs_confirm_cancel_session": "Bạn chắc chắn muốn hủy phiên live này?",
  "bs_confirm_cancel_session_live_success": "Hủy phiên live thành công",
  "bs_confirm_cancel_session_title": "Thông tin chưa được lưu lại. Bạn chắc chắn muốn hủy?",
  "bs_confirm_popup_confirm": "Gửi yêu cầu",
  "bs_confirm_uncheck_kol": "Bạn có chắc chắn hủy chọn KOL này không?",
  "bs_connect": "Kết nối",
  "bs_connect_shop": "Kết nối lại",
  "bs_connecting": "Đang kết nối",
  "bs_content_message_create_sku_success": "Tạo mới sản phẩm thành công",
  "bs_content_message_edit_sku_success": "Chỉnh sửa thành công",
  "bs_content_message_export_error_limit_data_row": "Hệ thống chỉ hỗ trợ tối đa 5000 bản ghi 1 lần xuất dữ liệu. Vui lòng filter data trước khi export.",
  "bs_content_message_export_success": "Export Complete",
  "bs_content_popup_confirm_delete": "Bạn chắc chắn muốn xóa link result này?",
  "bs_content_popup_confirm_delete_campagin": "Bạn có chắc chắn muốn xóa {{name}}? Hành động này không thể được hoàn tác.",
  "bs_content_popup_confirm_delete_multiple": "Bạn chắc chắn muốn xóa {{num}} link result này?",
  "bs_content_popup_confirm_delete_multiple_sku": "Bạn có chắc chắn muốn xóa {{num}} SKU này?  Hành động này không thể được hoàn tác.",
  "bs_content_popup_confirm_delete_multiple_tiktok": "Bạn chắc chắn muốn xóa {{num}} link này?",
  "bs_content_popup_confirm_delete_sku": "Bạn có chắc chắn muốn xóa {{name}} này?  Hành động này không thể được hoàn tác.",
  "bs_content_popup_confirm_delete_tiktok": "Bạn chắc chắn muốn xóa link này?",
  "bs_content_popup_confirm_save_kol": "Ecomobi sẽ giúp bạn liên hệ và làm việc với những KOC đã được chọn.",
  "bs_continue": "Lưu",
  "bs_conversion_time": "Ngày tạo",
  "bs_copied_text": "Đã sao chép",
  "bs_copy_text": "Sao chép liên kết",
  "bs_cost": "Chi phí",
  "bs_cost_effective_content": "Competitive and flexible price models for many scales and demands of Brands",
  "bs_cost_effective_description": "Competitive, flexible price models suitable for many scales and demands of Brands. Less cost – more value is our operating principle.",
  "bs_cost_effective_title": "Cost - Effective",
  "bs_cost_effective_title_1": "Free System Initialization Fee",
  "bs_cost_effective_title_2": "Flexible Fee Package",
  "bs_cost_effective_title_3": "Advance and Billing",
  "bs_countries": "Countries",
  "bs_country": "Country",
  "bs_country_code": "Quốc gia",
  "bs_country_code_is_required": "Quốc gia không được bỏ trống",
  "bs_country_id": "Indonesia",
  "bs_country_my": "Malaysia",
  "bs_country_other": "Khác",
  "bs_country_ph": "Philippines",
  "bs_country_sg": "Singapore",
  "bs_country_th": "Thái Lan",
  "bs_country_vn": "Việt Nam",
  "bs_cpo_tooltip_content": "Commission Per Order = Commission Actual / Total Order ",
  "bs_create_campaign": "Tạo chiến dịch",
  "bs_create_campaign_success": "Tạo chiến dịch thành công",
  "bs_create_host_title": "Tạo mới host",
  "bs_create_livestream": "Tạo mới phiên Live",
  "bs_create_livestream_label_day": "Tạo mới phiên live ngày",
  "bs_create_livestream_placeholder": "Chọn {{field}}",
  "bs_create_new": "Tạo mới",
  "bs_create_new_sku": "Thêm mới",
  "bs_create_request": "Tạo yêu cầu",
  "bs_create_room_title": "Tạo mới phòng live",
  "bs_create_sku": "Thêm mới sản phẩm",
  "bs_creator_connect_tooltip_aov": "Giá trị đơn hàng trung bình của Creator",
  "bs_creator_connect_tooltip_nmv": "Những đơn hàng thành công",
  "bs_creators": "Nhà sáng tạo",
  "bs_creators_az": "Creators A-Z",
  "bs_creators_joined": "Creators Joined",
  "bs_currency": "Tiền tệ:",
  "bs_cvr_tooltip_content": "Conversion Rate =Total Order / Unique Click ",
  "bs_dashboard_chart_title_active_post": "Số KOC đăng bài",
  "bs_dashboard_chart_title_engagement": "Tương tác",
  "bs_dashboard_chart_title_post": "Bài đăng",
  "bs_dashboard_data_engagements": "Tương tác",
  "bs_dashboard_data_koc_joined": "KOC tham gia",
  "bs_dashboard_data_posts": "Bài đăng",
  "bs_dashboard_data_views": "Lượt xem",
  "bs_dashboard_description_not_connect_shop": "Kết nối cửa hàng để hoàn thành báo cáo này",
  "bs_dashboard_login_title": "Dashboard được thiết kế đặc biệt với mục tiêu",
  "bs_dashboard_page": "Dashboard",
  "bs_dashboard_placeholder_select_campaign": "- Chọn chiến dịch -",
  "bs_dashboard_sub_title_report_booking": "Báo cáo tháng này",
  "bs_dashboard_title": "Dashboard",
  "bs_dashboard_title_data_order_sale": "Hiệu quả bán hàng",
  "bs_dashboard_title_not_connect_shop": "Chưa có dữ liệu thống kê",
  "bs_dashboard_title_overview_engagements": "Hiệu quả tương tác",
  "bs_dashboard_title_report_affiliate": "Báo cáo hiệu quả tiếp thị liên kết",
  "bs_dashboard_title_report_booking": "Báo cáo hiệu quả chiến dịch booking",
  "bs_dashboard_title_table_top_live": "Top Livestream",
  "bs_dashboard_title_table_top_video": "Top Video",
  "bs_day": "Ngày",
  "bs_day_selected": "Chi tiết ngày",
  "bs_delete": "Xóa",
  "bs_delete_all": "Xóa tất cả",
  "bs_delete_popup": "Xóa",
  "bs_delete_popup_confirm": "Xác nhận xóa",
  "bs_delivered": "Đã giao hàng",
  "bs_description_modal_connect_host": "Tài khoản của bạn hiện chưa được liên kết với nhãn hàng nào.  Hãy nhập mã liên kết nhận được từ nhãn hàng để theo dõi lịch live stream của bạn",
  "bs_detail": "Chi tiết",
  "bs_detail_campaign": "Chi tiết chiến dịch",
  "bs_detail_list_kol": "Danh sách KOC",
  "bs_detail_sku_title_table": "Danh sách Creator cộng tác",
  "bs_disconnect_shop": "Ngắt kết nối cửa hàng",
  "bs_disconnected": "Đã dừng kết nối",
  "bs_disconnecting": "Dừng kết nối",
  "bs_discovery_brand": "Discovery Brand",
  "bs_discovery_search_placeholder": "Search with brand name",
  "bs_discovery_text_GMV": "GMV",
  "bs_discovery_text_Orders": "Đơn hàng",
  "bs_discovery_text_Products": "Products",
  "bs_discovery_text_SALE": "SALE",
  "bs_discovery_text_SKUs": "SKUs",
  "bs_discovery_text_Sale_ucfist": "Sale",
  "bs_discovery_text_USD": "USD",
  "bs_discovery_text_Users": "Users",
  "bs_discovery_text_Videos": "Video",
  "bs_discovery_text_influencer": "Influencers",
  "bs_discovery_text_live": "Sessions",
  "bs_discovery_text_relevant_influencer": "Relevant Influencer",
  "bs_discovery_text_relevant_live": "Relevant Live",
  "bs_discovery_text_video_by_brand": "Relevant Video",
  "bs_discovery_text_views": "Views",
  "bs_discovery_title_avg_view": "Most Average View",
  "bs_discovery_title_gmv": "Most GMV",
  "bs_discovery_title_relevant_influencer": "Most Relevant Influencer",
  "bs_discovery_title_sale": "Most Sale",
  "bs_download": "Tải xuống",
  "bs_download_file_error": "Tải xuống file lỗi",
  "bs_download_file_warning": "Tải file",
  "bs_easier_recruiting_content": "Access to 100.000+ KOLs/ Creators who certainly match Brands' campaigns",
  "bs_easier_recruiting_description": "Join us to access a vast pool of 100.000+ qualified KOLs/ Creators/ Influencers who certainly match your campaigns.",
  "bs_easier_recruiting_title": "Easier Recruiting",
  "bs_easier_recruiting_title_1": "100.000+ Qualified KOLs/ Creators",
  "bs_easier_recruiting_title_2": "Traffic Per Month",
  "bs_easier_recruiting_title_3": "20+ Various Brand Categories",
  "bs_easier_recruiting_title_4": "KOL/ Creator Profile",
  "bs_ecomerce": "Thương mại điện tử",
  "bs_ecomobi_passio": "Ecomobi Passio",
  "bs_edit": "Chỉnh sửa",
  "bs_edit_connect_host_description": "Host chưa được liên kết với tài khoản Passio, hãy hướng dẫn host liên kết tài khoản để theo dõi phiên live của mình",
  "bs_edit_connect_host_title": "Hướng dẫn liên kết tài khoản",
  "bs_edit_host_title": "Chỉnh sửa thông tin host",
  "bs_edit_livestream": "Cập nhật phiên Live",
  "bs_edit_livestream_title": "Chỉnh sửa phiên live",
  "bs_edit_room_title": "Chỉnh sửa phòng live",
  "bs_edit_sku": "Chi tiết sản phẩm",
  "bs_edit_sku_content_popup_confirm_delete_sku": "Bạn có chắc chắn muốn xóa {{name}} này?  Hành động này không thể được hoàn tác.",
  "bs_edit_sku_field_category": "Danh mục",
  "bs_edit_sku_field_fb_sku_id": "Mã sản phẩm Facebook",
  "bs_edit_sku_field_insta_sku_id": "Mã sản phẩm Instagram",
  "bs_edit_sku_field_lzd_sku_id": "Mã sản phẩm Lazada",
  "bs_edit_sku_field_main_sku_id": "Mã sản phẩm",
  "bs_edit_sku_field_product_img": "Ảnh sản phẩm",
  "bs_edit_sku_field_shopee_sku_id": "Mã sản phẩm Shopee",
  "bs_edit_sku_field_sku_name": "Tên sản phẩm",
  "bs_edit_sku_field_sub_brand_id": "Sub Brand",
  "bs_edit_sku_field_subsegment": "Subsegment",
  "bs_edit_sku_field_tiki_sku_id": "Mã sản phẩm Tiki",
  "bs_edit_sku_field_tiktok_sku_id": "Mã sản phẩm Tiktok",
  "bs_edit_sku_placeholder_field_category": "Chọn Category",
  "bs_edit_sku_placeholder_field_fb_sku_id": "VD: SPFB9999999",
  "bs_edit_sku_placeholder_field_insta_sku_id": "VD: SPIT9999999",
  "bs_edit_sku_placeholder_field_lzd_sku_id": "VD: SPLZD9999999",
  "bs_edit_sku_placeholder_field_main_sku_id": "VD: 9999999",
  "bs_edit_sku_placeholder_field_product_img": "Nhập đường dẫn ảnh",
  "bs_edit_sku_placeholder_field_shopee_sku_id": "VD: SPSP9999999",
  "bs_edit_sku_placeholder_field_sku_name": "VD: Sữa tắm cao cấp",
  "bs_edit_sku_placeholder_field_sub_brand_id": "Chọn Sub-brand",
  "bs_edit_sku_placeholder_field_subsegment": "Nhập nội dung",
  "bs_edit_sku_placeholder_field_tiki_sku_id": "VD: SPTK9999999",
  "bs_edit_sku_placeholder_field_tiktok_sku_id": "VD: SPTT9999999",
  "bs_edit_sku_title": "Thông tin sản phẩm",
  "bs_edit_sku_title_popup_confirm_delete_sku": "Xóa sản phẩm này?",
  "bs_education": "Giáo dục",
  "bs_ellana_cosmetic": "Ellana Cosmetic",
  "bs_ellana_cosmetic_content": "Ellana Cosmetic collaborates with KOLs/ Creators in Ecomobi Passio for attracting users to increase purchases in Ellana's Official Shopee Store. See what impressive performance per month Ellana gets for their brand!",
  "bs_email_not_exist": "Email không chính xác",
  "bs_email_used": "Email đã được sử dụng",
  "bs_empty_campaign": "Hiện tại chưa có chiến dịch nào của bạn",
  "bs_empty_data_influencer": "Chưa có dữ liệu Influencer",
  "bs_empty_data_table": "Không có dữ liệu",
  "bs_empty_in_table_brand_booking": "Không có dữ liệu",
  "bs_empty_in_table_campaign": "Chưa có link nghiệm thu nào",
  "bs_empty_in_table_list_video_job": "Chưa có danh sách yêu cầu nào",
  "bs_empty_in_table_tiktok_counter": "Không có dữ liệu",
  "bs_empty_kol": "Hiện tại chưa có KOL phù hợp với chiến dịch của bạn",
  "bs_en": "Tiếng Anh",
  "bs_end_date_label": "Ngày kết thúc",
  "bs_english": "English",
  "bs_enter_field_name": "Nhập {{field}}",
  "bs_entertainment": "Giải trí",
  "bs_error_add_category_existed": "Danh mục đã tồn tại",
  "bs_error_add_category_required": "Vui lòng điền tên danh mục",
  "bs_error_add_sub_brand_existed": "Sub-brand đã tồn tại",
  "bs_error_add_sub_brand_required": "Vui lòng điền tên danh mục",
  "bs_error_code_connect_passio": "Mã liên kết không chính xác hoặc đã được sử dụng. Vui lòng kiểm tra lại với nhãn hàng",
  "bs_error_data_invalid": "Dữ liệu không hợp lệ",
  "bs_error_field_category": "Bạn chưa chọn Danh mục",
  "bs_error_field_main_sku_id": "Bạn chưa nhập Mã sản phẩm",
  "bs_error_field_sku_name": "Bạn chưa nhập Tên sản phẩm",
  "bs_error_field_sub_brand_id": "Bạn chưa chọn sub-brand",
  "bs_error_field_subsegment": "Bạn nhập quá số ký tự. Vui lòng nhập ít hơn 255 ký tự!",
  "bs_error_host_is_using": "Phòng live đang được sử dụng, không thể xóa",
  "bs_error_host_name_existed": "Tên host này đã tồn tại",
  "bs_error_passio_username_wrong": "Tên người dùng này không đúng. Vui lòng kiểm tra lại",
  "bs_error_room_name_error": "Đã tồn tại phòng live \"{{name}}\" ở trạng thái Đang hoạt động. Vui lòng kiểm tra lại",
  "bs_error_room_name_existed": "Tên phòng đã tồn tại ở trạng thái Đang hoạt động",
  "bs_error_row_import": "There are {{num}} invalid records in this file. Please check again",
  "bs_exist_value": "Đã tồn tại giá trị này",
  "bs_expert_support_content": "24/7 professional support team to assist Brands in getting the highest performance",
  "bs_expert_support_description": "Our 24/7 professional support team assists you in getting the highest performance through how-to guides and Sales-Marketing optimization.",
  "bs_expert_support_title": "Expert Support",
  "bs_expert_support_title_1": "1-1 Support With Our Expert Team",
  "bs_expert_support_title_2": "Experienced With 1.000+ Campaigns Per Year",
  "bs_expert_support_title_3": "Advice On Building Marketing & Sales Channels",
  "bs_export": "Export Report",
  "bs_facebook": "Facebook",
  "bs_fashion": "Thời trang",
  "bs_fast_exactly_result": "Cập nhật kết quả triển khai và báo cáo toàn diện, chính xác",
  "bs_female": "Nữ ",
  "bs_field_brand_description_is_required": "Mô tả brands & sản phẩm không được bỏ trống",
  "bs_field_request_content_is_required": "Mô tả yêu cầu không được bỏ trống",
  "bs_field_required": "Trường thông tin là bắt buộc",
  "bs_filter": "Lọc",
  "bs_find_out_more": "Find out more",
  "bs_first": "first",
  "bs_float_button_user_manual": "Hướng dẫn sử dụng",
  "bs_fnb": "F&B",
  "bs_for_brands": "For Brands",
  "bs_for_creator": "For Creator",
  "bs_forgot_password": "Forgot Password?",
  "bs_forgot_password_description": "Nhập địa chỉ e-mail để đặt lại mật khẩu",
  "bs_forgot_password_failed_title": "Quên mật khẩu thất bại",
  "bs_forgot_password_success_content": "Mật khẩu mới đã được cập nhật",
  "bs_forgot_password_success_title": "Thay đổi mật khẩu thành công",
  "bs_from": "Từ",
  "bs_get_left_behind": "get left behind",
  "bs_get_verification_code": "Nhận mã xác thực",
  "bs_get_verification_code_maximum": "Đã hết lượt",
  "bs_gmv": "GMV",
  "bs_gmv_actual": "Doanh thu thực tế",
  "bs_gmv_by_store": "Doanh thu theo cửa hàng",
  "bs_grow_revenue_or": "grow revenue or",
  "bs_harness_social_selling": "Harness social selling",
  "bs_head_quarter": "HeadQuarter",
  "bs_healthy": "Sức khỏe",
  "bs_helper_attach_file": "Hệ thống hỗ trợ thống kê chỉ số của các link nghiệm thu thuộc kênh mạng xã hội Tiktok",
  "bs_helper_download_template_import": "Hỗ trợ file định dạng xlsx và csv, tối đa 500 dòng",
  "bs_helper_download_template_import_max_4MB": "Hỗ trợ file định dạng xlsx, tối đa 4MB",
  "bs_helper_download_template_import_max_num": "Hỗ trợ file định dạng xlsx và csv, tối đa {{num}} dòng",
  "bs_helper_download_xlsx_template_import_max_num": "Hỗ trợ file định dạng xlsx. Tối đa {{num}} dòng",
  "bs_helper_drag_file": "Chưa có tệp được chọn",
  "bs_helper_empty_data_search": "Vui lòng thử lại",
  "bs_helper_import_file_campaign": "Tạo nhanh thông tin booking campaign từ file quản lý có sẵn để quản lý trên hệ thống",
  "bs_helper_import_file_tiktok_counter": "Hệ thống chỉ hỗ trợ đường dẫn video Tiktok",
  "bs_helper_linked_account": "Nhập tên tài khoản Passio mà Host sử dụng để theo dõi phiên live",
  "bs_hide_chart": "Ẩn biểu đồ",
  "bs_home_page": "Home page",
  "bs_homepage": "Homepage",
  "bs_host_create_livestream_title_popup": "Tạo mới phiên live ngày",
  "bs_host_detail_livestream": "Chi tiết live",
  "bs_host_name": "Tên Host",
  "bs_host_overview_report": "Báo cáo tổng quan",
  "bs_houseware": "Dụng cụ gia đình",
  "bs_import": "Import",
  "bs_import_campaign": "Import Campaign",
  "bs_import_create_live": "Tải lên phiên live mới",
  "bs_import_error_file_excel_max": "Import không thành công, vui lòng chọn tệp có số hàng tối đa là 500",
  "bs_import_error_file_excel_max_with_rows": "Import không thành công, vui lòng chọn tệp có số hàng tối đa là {{num}}",
  "bs_import_error_file_excel_total": "File của bạn đang có {{num}} bản ghi không hợp lệ",
  "bs_import_error_file_excel_type": "Import không thành công, vui lòng chọn tệp có định dạng xlsx hoặc csv",
  "bs_import_error_file_excel_type_xlsx": "Import không thành công, vui lòng chọn tệp có định dạng xlsx",
  "bs_import_result": "Import link",
  "bs_import_result_live": "Tải lên kết quả live",
  "bs_import_warning_file_excel_total": "Trong file có {{num}} bản ghi không phải các sản phẩm của bạn. Các bản ghi này sẽ không thể hiển thị sau khi import",
  "bs_inactive": "Inactive",
  "bs_inactive_account": "Tài khoản của bạn hiện không hoạt động. Vui lòng liên hệ bộ phận hỗ trợ để được trợ giúp.",
  "bs_indo": "Indonesia",
  "bs_indonesia": "Indonesia",
  "bs_indonesia_branch": "Indonesia Branch",
  "bs_info_campaign": "Thông tin chiến dịch",
  "bs_insights_search_placeholder": "Search with Influencer name",
  "bs_instagram": "Instagram",
  "bs_invalid_field": "Thông tin không đúng định dạng",
  "bs_item": "Item",
  "bs_join_date": "Ngày tham gia",
  "bs_key_date_vietnamese": "Thứ",
  "bs_key_sunday_vietnam": "Chủ nhật",
  "bs_keyword_requirement": "Từ Khóa Yêu Cầu",
  "bs_keyword_unaccepted": "Từ Khóa Nghiêm Cấm",
  "bs_kinds": "Hình thức",
  "bs_kinds_is_required": "Hình thức không được bỏ trống",
  "bs_koc_dashboard": "KOC Dashboard",
  "bs_koc_dashboard_description": "Tổng quan về chất lượng của các KOC tham gia các chiến dịch của bạn",
  "bs_koc_information": "KOC Information",
  "bs_koc_join": "KOC Tham Gia Chiến Dịch",
  "bs_koc_joined_tooltip": "Là tất cả các KOC có gửi yêu cầu nhận gói ưu đãi",
  "bs_koc_listing": "Danh Sách KOC",
  "bs_koc_listing_title": "Danh sách KOC",
  "bs_koc_order": "KOC Có Đơn Hàng",
  "bs_koc_profile": "Hồ sơ KOC",
  "bs_kol": "KOL",
  "bs_kol_range_age": "Tuổi KOC",
  "bs_kol_range_age_is_required": "Tuổi Kols không được bỏ trống",
  "bs_kol_report_title": "KOL report",
  "bs_label_accept_the_post": "Nghiệm thu bài đăng",
  "bs_label_add_delivery_info": "Thêm thông tin",
  "bs_label_agency_not_review": "Agency chưa đánh giá",
  "bs_label_attach_file": "File nghiệm thu",
  "bs_label_average_time_receive_video": "Thời gian nhận video trung bình",
  "bs_label_brand_not_review": "Nhãn hàng chưa đánh giá",
  "bs_label_campaign": "Chiến dịch",
  "bs_label_change_live_time": "Gửi yêu cầu đổi lịch live",
  "bs_label_channel": "Kênh",
  "bs_label_chart_trend_each_index": "Biểu đồ xu hướng mỗi chỉ số",
  "bs_label_charts_activated_koc": "Activated KOC",
  "bs_label_charts_engagements": "Engagement",
  "bs_label_charts_posts": "Post",
  "bs_label_checkin_live": "Thông tin check-in",
  "bs_label_choose_the_date": "Ngày đăng bài",
  "bs_label_colum_AOV": "AOV",
  "bs_label_colum_KOLS": "KOL Number",
  "bs_label_colum_action": "Thao tác",
  "bs_label_colum_actual_comission": "Hoa hồng thực tế",
  "bs_label_colum_actual_commissions_base": "Cơ sở hoa hồng thực tế",
  "bs_label_colum_actual_orders": "Đơn hàng thực tế",
  "bs_label_colum_actual_revenue": "Doanh thu thực tế",
  "bs_label_colum_address": "Địa chỉ",
  "bs_label_colum_ads_code": "Mã quảng cáo",
  "bs_label_colum_agency": "Agency",
  "bs_label_colum_agency_review": "Agency đánh giá",
  "bs_label_colum_amount": "Doanh thu",
  "bs_label_colum_area": "Khu vực",
  "bs_label_colum_avg_view": "Average View",
  "bs_label_colum_avg_viewing_duration": "Thời gian xem trung bình",
  "bs_label_colum_booking_cost": "Chi phí booking",
  "bs_label_colum_brand": "Brand",
  "bs_label_colum_brand_name": "Brand Name",
  "bs_label_colum_brand_review": "Nhãn hàng đánh giá",
  "bs_label_colum_campaign_id": "Campaign id",
  "bs_label_colum_campaign_name": "Tên chiến dịch",
  "bs_label_colum_cancel_rate": "Tỉ lệ huỷ",
  "bs_label_colum_cancel_rating": "Tỉ lệ hủy",
  "bs_label_colum_comission": "Hoa hồng ước tính",
  "bs_label_colum_comment": "Tổng bình luận",
  "bs_label_colum_commission_cost": "Chi phí hoa hồng",
  "bs_label_colum_commissions": "Tỷ lệ hoa hồng",
  "bs_label_colum_cost_actual": "Chi phi thực tế",
  "bs_label_colum_cost_estimate": "Chi phi dự kiến",
  "bs_label_colum_country": "Quốc gia",
  "bs_label_colum_create_date": "Ngày tạo",
  "bs_label_colum_create_time": "Thời gian đặt hàng",
  "bs_label_colum_creator": "Nhà sáng tạo",
  "bs_label_colum_creator_commissions": "Hoa hồng cho nhà sáng tạo",
  "bs_label_colum_creator_contact": "Số Creator hợp tác",
  "bs_label_colum_creator_info": "Thông tin Creator",
  "bs_label_colum_creator_post": "Số Creator đã đăng bài",
  "bs_label_colum_creator_sale": "Số Creator có doanh số",
  "bs_label_colum_date": "Thời gian",
  "bs_label_colum_drop_live": "Tỉ lệ bỏ live",
  "bs_label_colum_duration": "Thời lượng",
  "bs_label_colum_duration_actual": "Thời lượng thực tế",
  "bs_label_colum_duration_estimate": "Thời lượng dự kiến",
  "bs_label_colum_effective_time": "Thời gian hiệu lực",
  "bs_label_colum_estimate_commissions_base": "Cơ sở hoa hồng ước tính",
  "bs_label_colum_estimate_revenue": "Doanh thu dự kiến",
  "bs_label_colum_follower": "Followers",
  "bs_label_colum_gmv": "GMV (USD)",
  "bs_label_colum_gmv_cancel": "Doanh thu đã hủy",
  "bs_label_colum_gmv_complete": "Doanh thu đã xác nhận",
  "bs_label_colum_gmv_pending": "Doanh thu đang xử lý",
  "bs_label_colum_gmv_waiting": "Doanh thu chờ duyệt",
  "bs_label_colum_id_live_stream": "ID livestream",
  "bs_label_colum_index": "STT",
  "bs_label_colum_influencer_name": "Influencer Name",
  "bs_label_colum_info_creator": "Thông tin nhà sáng tạo",
  "bs_label_colum_item_cancel": "Sản phẩm đã hủy",
  "bs_label_colum_item_complete": "SP đã hoàn thành",
  "bs_label_colum_item_pending": "SP đang xử lý",
  "bs_label_colum_kol": "KOL",
  "bs_label_colum_kol_host": "Host",
  "bs_label_colum_kol_name": "KOL",
  "bs_label_colum_kol_username": "Username",
  "bs_label_colum_like": "Tổng likes",
  "bs_label_colum_link_air": "Link Air",
  "bs_label_colum_link_profile": "Link Profile",
  "bs_label_colum_link_submit": "Link Submit",
  "bs_label_colum_live_actual": "Phiên live thực tế",
  "bs_label_colum_live_estimate": "Phiên live dự kiến",
  "bs_label_colum_live_info": "Thông tin live",
  "bs_label_colum_live_session_quit_live": "Số phiên bỏ live",
  "bs_label_colum_live_stream": "Livestream",
  "bs_label_colum_livestream": "Số phiên live",
  "bs_label_colum_livestream_estimate": "Số phiên live ước tính",
  "bs_label_colum_livestreams": "Livestreams",
  "bs_label_colum_name": "Tên",
  "bs_label_colum_name_average_time_receive_video": "Thời gian nhận video trung bình",
  "bs_label_colum_name_cost": "Chi phí (Thực tế/Dự kiến)",
  "bs_label_colum_name_job": "Tên job",
  "bs_label_colum_name_kol_approved": "KOL đã duyệt",
  "bs_label_colum_name_kol_request": "KOL đăng ký",
  "bs_label_colum_name_live": "Tên phiên live",
  "bs_label_colum_name_live_room": "Tên phòng live",
  "bs_label_colum_name_number_video_accepted": "Số video accepted",
  "bs_label_colum_name_number_video_order": "Số video order",
  "bs_label_colum_name_number_video_submit": "Số video submit",
  "bs_label_colum_name_status": "Trạng thái",
  "bs_label_colum_name_target": "Mục tiêu",
  "bs_label_colum_name_type_video_order": "Loại video order",
  "bs_label_colum_no": "No.",
  "bs_label_colum_order": "Tổng đơn hàng",
  "bs_label_colum_order_cancel": "Đơn hủy",
  "bs_label_colum_order_complete": "Đơn hoàn thành",
  "bs_label_colum_order_paid": "Đơn hàng đã thanh toán",
  "bs_label_colum_order_pending": "Đơn đang xử lý",
  "bs_label_colum_partner_commissions": "Hoa hồng cho đối tác",
  "bs_label_colum_percentage": "Phần trăm loading(%)",
  "bs_label_colum_platform": "Tên nền tảng",
  "bs_label_colum_post": "Bài đăng",
  "bs_label_colum_price": "Giá bán",
  "bs_label_colum_price_and_quantity": "Giá & số lượng",
  "bs_label_colum_product": "Sản phẩm",
  "bs_label_colum_product_click": "Lượt click",
  "bs_label_colum_product_id": "ID sản phẩm",
  "bs_label_colum_product_impression": "Lượt hiển thị",
  "bs_label_colum_product_info": "Thông tin sản phẩm",
  "bs_label_colum_product_name": "Tên sản phẩm",
  "bs_label_colum_product_sample": "Sản phẩm mẫu",
  "bs_label_colum_product_sold": "Sản phẩm đã bán",
  "bs_label_colum_products": "Products (SKUs)",
  "bs_label_colum_quantity": "Số lượng",
  "bs_label_colum_rating": "Đánh giá",
  "bs_label_colum_relevant_influencer": "Relevant Influencer",
  "bs_label_colum_relevant_live": "Relevant Live",
  "bs_label_colum_relevant_videos": "Relevant Video",
  "bs_label_colum_rev": "Doanh thu",
  "bs_label_colum_rev_actual": "Doanh thu thực tế",
  "bs_label_colum_rev_estimate": "Doanh thu dự kiến",
  "bs_label_colum_revenue": "Tổng doanh thu",
  "bs_label_colum_revenue_live_hour": "Doanh thu/giờ live",
  "bs_label_colum_revenue_live_session": "Doanh thu/Phiên",
  "bs_label_colum_revenue_orders": "Doanh thu",
  "bs_label_colum_revenue_video": "Doanh thu/Video",
  "bs_label_colum_sale": "Sale (Orders)",
  "bs_label_colum_sale_ammount": "Số lượng bán",
  "bs_label_colum_sale_per_day": "Lượt bán mỗi ngày",
  "bs_label_colum_sales": "Lượt bán",
  "bs_label_colum_sample_status": "Trạng thái mẫu",
  "bs_label_colum_settlement": "Đơn hàng đã quyết toán",
  "bs_label_colum_share": "Lượt chia sẻ",
  "bs_label_colum_shop_code": "Mã cửa hàng",
  "bs_label_colum_shop_name": "Tên cửa hàng",
  "bs_label_colum_sku": "Mã",
  "bs_label_colum_sku_info": "Phân loại",
  "bs_label_colum_sold_count": "Mặt hàng đã bán",
  "bs_label_colum_status": "Trạng thái",
  "bs_label_colum_status_description": "BẬT để không giới hạn số phiên live sử dụng phòng live này tại một thời điểm",
  "bs_label_colum_status_submit": "Đã Submit",
  "bs_label_colum_sub_brand": "Sub-brand",
  "bs_label_colum_suggested_creator": "Creator được đề xuất",
  "bs_label_colum_tiktok_username": "Tiktok username",
  "bs_label_colum_time": "Thời gian diễn ra",
  "bs_label_colum_time_approve": "Thời gian duyệt",
  "bs_label_colum_time_reject": "Thời gian từ chối",
  "bs_label_colum_time_request": "Ngày đăng ký",
  "bs_label_colum_top_index": "Xếp hạng",
  "bs_label_colum_top_product": "Top 3 sản phẩm bán chạy",
  "bs_label_colum_total_buyer": "Tổng người mua",
  "bs_label_colum_total_gmv": "Tổng doanh thu",
  "bs_label_colum_total_item": "Tổng",
  "bs_label_colum_total_order": "Đơn hàng",
  "bs_label_colum_total_posts": "Bài Submit",
  "bs_label_colum_total_revenue": "Tổng quan doanh số",
  "bs_label_colum_total_sale": "Tổng lượt bán",
  "bs_label_colum_total_social": "Tổng quan tương tác",
  "bs_label_colum_total_view": "Tổng lượt xem",
  "bs_label_colum_user_name": "Username",
  "bs_label_colum_video": "Video",
  "bs_label_colum_video_count": "Số Booking",
  "bs_label_colum_view": "Lượt xem",
  "bs_label_column_delivery_info": "Thông tin vận chuyển",
  "bs_label_column_receiver": "Thông tin nhận hàng",
  "bs_label_comments_count": "Comments Count",
  "bs_label_congratulations_purchase_success": "Chúc mừng bạn mua thành công",
  "bs_label_crawl_status": "Trạng thái Craw Data",
  "bs_label_created_type": "Phân loại",
  "bs_label_creator_approved": "Creator đã duyệt",
  "bs_label_creator_order": "Creator ra đơn",
  "bs_label_creator_posted": "Creator lên bài",
  "bs_label_creator_request_product": "Creator yêu cầu sản phẩm",
  "bs_label_delivery_code": "Mã vận đơn",
  "bs_label_delivery_unit": "Đơn vị vận chuyển",
  "bs_label_diggs_count": "Diggs Count",
  "bs_label_download_error_file": "Tải xuống file lỗi",
  "bs_label_error_record": "bản ghi lỗi",
  "bs_label_gmv_from": "GMV từ",
  "bs_label_gmv_target_done": "Mục tiêu đã thiết lập",
  "bs_label_gmv_target_older": "Cũ hơn",
  "bs_label_import_file_campaign": "File Campaign",
  "bs_label_import_file_livestream_session": "Chọn file",
  "bs_label_import_file_sku_management": "Chọn File",
  "bs_label_import_file_tiktok_counter": "Tệp kết quả",
  "bs_label_koc": "KOC",
  "bs_label_likes_count": "Likes Count",
  "bs_label_list_creators_in_campaign": "Thống kê creator từ các campaign TAP",
  "bs_label_live": "Số phiên live",
  "bs_label_live_calender_sorted": "Lịch live đã được xếp",
  "bs_label_name_number_follower": "Số FLW",
  "bs_label_none_live_time": "Chưa có lịch live nào",
  "bs_label_note": "Ghi chú (nếu có)",
  "bs_label_number_video_accepted": "Số video đã nghiệm thu",
  "bs_label_number_video_job_completed": "Số video job đã hoàn thành",
  "bs_label_number_video_job_created": "Số video job đã tạo",
  "bs_label_number_video_tested": "Số video đã nghiệm thu",
  "bs_label_payment_failed": "Thanh toán thất bại",
  "bs_label_payment_success": "Thanh toán thành công",
  "bs_label_plays_count": "Plays Count",
  "bs_label_posted_articles": "Bài đã đăng",
  "bs_label_posted_date": "Ngày đăng",
  "bs_label_range_gmv": "với GMV từ",
  "bs_label_reach_count": "Potential Reach",
  "bs_label_reactions_count": "Reactions Count",
  "bs_label_register_time_host": "Thời gian đăng ký của Host",
  "bs_label_request_ads_code": "Yêu cầu xem mã",
  "bs_label_result_content": "Nội dung bài đăng",
  "bs_label_saves_count": "Saves Count",
  "bs_label_search_for_creator": "Tìm kiếm nhà sáng tạo",
  "bs_label_select_type_comments": "Most Comments",
  "bs_label_select_type_engagement": "Most Engagement",
  "bs_label_select_type_gmv": "Most GMV",
  "bs_label_select_type_likes": "Most Likes",
  "bs_label_select_type_recent": "Most Recent",
  "bs_label_select_type_shares": "Most Shares",
  "bs_label_select_type_views": "Most View",
  "bs_label_set_up_target": "Thiết lập mục tiêu",
  "bs_label_shares_count": "Shares Count",
  "bs_label_status": "Trạng thái",
  "bs_label_status_request_ads_code": "Đã gửi yêu cầu",
  "bs_label_sub_brand": "Thương hiệu phụ",
  "bs_label_sum": "Tổng",
  "bs_label_time_live": "Số giờ đã live",
  "bs_label_total_cost": "Tổng chi phí",
  "bs_label_transaction_failed_title": "Giao dịch chưa thành công",
  "bs_label_transaction_failed_try_again": "Vui lòng thử lại các phương thức thanh toán khác",
  "bs_label_view_comment": "Xem nhận xét",
  "bs_label_view_list_content": "Xem bài đăng",
  "bs_label_views_count": "Views Count",
  "bs_label_violation": "Nội dung vi phạm",
  "bs_lable_add_more_gmv_level": "Thêm mức",
  "bs_last_month": "Tháng trước",
  "bs_later": "later",
  "bs_lemon8": "Lemon8",
  "bs_line": "Line",
  "bs_link_result": "Link nghiệm thu",
  "bs_list_campaign": "Danh sách chiến dịch",
  "bs_list_campaign_button_detail": "Xem chi tiết",
  "bs_list_campaign_country": "Quốc gia",
  "bs_list_campaign_program_code": "Mã chương trình",
  "bs_list_campaign_time_create": "Ngày tạo",
  "bs_list_campaign_time_to_happen": "Thời gian diễn ra",
  "bs_list_host_message_validate_mail": "Vui lòng nhập email hợp lệ",
  "bs_list_host_steps_connect_1": "Bước 1: Host đăng ký tài khoản passio",
  "bs_list_host_steps_connect_2": "Bước 2: Đăng nhập",
  "bs_list_host_steps_connect_3": "Bước 3: Kết nối tài khoản",
  "bs_list_host_steps_connect_sub_1": "Host đăng ký tài khoản passio tại đường dẫn dưới đây. Nếu đã có tài khoản, vui lòng bỏ qua bước này.",
  "bs_list_host_steps_connect_sub_2": "Đăng nhập vào hệ thống quản lý live tại đường dẫn",
  "bs_list_host_steps_connect_sub_3": "Nhập mã “<b>{{code}}</b>” vào popup xác thực liên kết để kết nối tài khoản và xem danh sách lịch live",
  "bs_list_host_title_delete_host": "Bạn có muốn xóa \"{{name}}\" không?",
  "bs_list_kol": "KOC đề xuất",
  "bs_list_kol_placeholder_input": "Tìm kiếm KOLs theo tên",
  "bs_list_kol_selected": "Danh sách KOL đã chọn",
  "bs_list_kol_total_selected": "{{totalKol}} KOL đã được chọn",
  "bs_list_livestream": "Quản lý Brand Live",
  "bs_list_order": "Danh sách đơn hàng",
  "bs_list_room_title_delete_room": "Bạn có muốn xóa phòng \"{{name}}\" không?",
  "bs_list_sku_in_tap_action_more": "Xem Creator được đề xuất",
  "bs_list_sku_title_modal_edit_sku": "Thông tin sản phẩm mẫu",
  "bs_list_video_package_title": "Video Job",
  "bs_live_calendar_brand_logo_or_url_required": "Vui lòng tải ảnh hoặc nhập url",
  "bs_live_calendar_change_live_time_success": "Gửi yêu cầu thay đổi lịch live thành công",
  "bs_live_calendar_checkin_success": "Check-in thành công",
  "bs_live_calendar_rating_1_star": "Tệ",
  "bs_live_calendar_rating_2_star": "Không hài lòng",
  "bs_live_calendar_rating_3_star": "Bình thường",
  "bs_live_calendar_rating_4_star": "Hài lòng",
  "bs_live_calendar_rating_5_star": "Tuyệt vời",
  "bs_live_calendar_rating_failed": "Đánh giá thất bại",
  "bs_live_calendar_rating_note": "Nhận xét",
  "bs_live_calendar_rating_note_placeholder": "Nhập nội dung",
  "bs_live_calendar_rating_popup_title": "Đánh giá của bạn",
  "bs_live_calendar_rating_success": "Đánh giá thành công",
  "bs_live_calendar_rating_success_v2": "Đánh giá phiên live thành công",
  "bs_live_campagin_kol_host_detail_title": "Host report",
  "bs_live_campagin_kol_host_report_title": "Host report",
  "bs_live_campagin_kol_manage_room_live_title": "Quản lí phòng live",
  "bs_live_performance_label_campaign": "Chiến dịch",
  "bs_live_performance_label_hour": "giờ",
  "bs_live_performance_label_reach_performance": "Hiệu quả tiếp cận",
  "bs_live_performance_label_sale_performance": "Hiệu quả bán hàng",
  "bs_live_performance_label_session": "phiên",
  "bs_live_performance_label_top_agency": "BXH Agency",
  "bs_live_performance_label_top_kol": "BXH Host",
  "bs_live_performance_label_top_livestream": "BXH phiên live",
  "bs_live_performence_c_o": "C_O trung bình",
  "bs_live_performence_campaign": "Số chiến dịch",
  "bs_live_performence_ctr": "CTR trung bình",
  "bs_live_performence_kol_host": "Host",
  "bs_live_performence_report_menu": "Hiệu quả Brand Live",
  "bs_live_performence_target_revenue": "Doanh thu mục tiêu",
  "bs_live_performence_title_page": "Báo cáo hiệu quả Livestream",
  "bs_live_performence_total_buyer": "Tổng người mua",
  "bs_live_performence_total_click": "Số click sản phẩm",
  "bs_live_performence_total_comment": "Tổng bình luận",
  "bs_live_performence_total_cost": "Chi phí",
  "bs_live_performence_total_like": "Tổng lượt thích",
  "bs_live_performence_total_live": "Số phiên live",
  "bs_live_performence_total_order": "Tổng  đơn hàng",
  "bs_live_performence_total_product": "Tổng sản phẩm",
  "bs_live_performence_total_revenue": "Tổng doanh thu",
  "bs_live_performence_total_time": "Tổng thời lượng",
  "bs_live_performence_total_view": "Tổng lượt xem",
  "bs_live_performence_total_viewer": "Tổng người xem",
  "bs_live_revenue_label": "Doanh thu phiên live",
  "bs_live_stream": "Livestream",
  "bs_livestream": "Livestream",
  "bs_livestream_another_lives_session": "Phiên live khác",
  "bs_livestream_calendar": "Lịch livestream",
  "bs_livestream_calendar_add_info": "Bổ sung thông tin",
  "bs_livestream_calendar_agency": "Agency",
  "bs_livestream_calendar_agency_rating": "Agency đánh giá",
  "bs_livestream_calendar_agree_and_edit": "Đồng ý và chỉnh sửa",
  "bs_livestream_calendar_agree_label": "Xác nhận",
  "bs_livestream_calendar_appearance": "Ngoại hình",
  "bs_livestream_calendar_approve_by": "Người duyệt",
  "bs_livestream_calendar_approved": "Đã duyệt",
  "bs_livestream_calendar_avg_score": "Điểm trung bình",
  "bs_livestream_calendar_being_rejected": "Bị từ chối",
  "bs_livestream_calendar_brand": "Nhãn hàng",
  "bs_livestream_calendar_brand_no_rating": "Bạn chưa đánh giá",
  "bs_livestream_calendar_brand_rating": "Nhãn hàng đánh giá",
  "bs_livestream_calendar_campaign": "Chiến dịch",
  "bs_livestream_calendar_cancel_label": "Huỷ",
  "bs_livestream_calendar_cancel_live": "Yêu cầu hủy lịch live",
  "bs_livestream_calendar_cancelled": "Đã hủy",
  "bs_livestream_calendar_change_live_time": "Đổi lịch live",
  "bs_livestream_calendar_change_live_time_warning": "Không thể đổi, hủy lịch live",
  "bs_livestream_calendar_change_live_time_warning_content": "Không thể tạo thêm yêu cầu khi có yêu cầu đang chờ duyệt",
  "bs_livestream_calendar_change_live_time_warning_content_about_time": "Phiên live sẽ diễn ra trong {{time}} giờ tới, bạn không thể yêu cầu thay đổi lịch live",
  "bs_livestream_calendar_change_live_time_warning_title": "Không thể yêu cầu thay đổi lịch live",
  "bs_livestream_calendar_change_time_live": "Yêu cầu đổi thông tin",
  "bs_livestream_calendar_check_in_now": "Check-in ngay",
  "bs_livestream_calendar_checked_in": "Đã check-in",
  "bs_livestream_calendar_close": "Đóng",
  "bs_livestream_calendar_effectiveness": "Hiệu quả livestream",
  "bs_livestream_calendar_expertise": "Kiến thức về ngành hàng",
  "bs_livestream_calendar_forget_check_in": "Quên check-in",
  "bs_livestream_calendar_full_time": "Đủ giờ",
  "bs_livestream_calendar_fully_prepared": "Chuẩn bị đầy đủ tư trang",
  "bs_livestream_calendar_img_checkin": "Hình ảnh check in",
  "bs_livestream_calendar_improve_gmv": "Cải thiện GMV",
  "bs_livestream_calendar_live": "Đang diễn ra",
  "bs_livestream_calendar_live_room": "Phòng live",
  "bs_livestream_calendar_live_session_chart": "Biểu đồ số phiên live qua từng ngày",
  "bs_livestream_calendar_live_time": "Giờ live",
  "bs_livestream_calendar_livestream_style": "Tác phong livestream",
  "bs_livestream_calendar_load_more_img": "Tải thêm ảnh",
  "bs_livestream_calendar_makeup": "Trang điểm",
  "bs_livestream_calendar_new_time": "Giờ live mới",
  "bs_livestream_calendar_no_plan": "Không có phiên live nào",
  "bs_livestream_calendar_no_rating": "Agency chưa đánh giá",
  "bs_livestream_calendar_not_rating": "Chưa đánh giá",
  "bs_livestream_calendar_not_yet": "Chưa diễn ra",
  "bs_livestream_calendar_note": "Ghi chú",
  "bs_livestream_calendar_own_work": "Không làm việc riêng",
  "bs_livestream_calendar_placeholder_reject_reason": "Nhập lý do từ chối",
  "bs_livestream_calendar_policy": "Cập nhật chính sách bán hàng",
  "bs_livestream_calendar_preserve": "Bảo quản thiết bị và giữ vệ sinh",
  "bs_livestream_calendar_quit_live": "Bỏ live",
  "bs_livestream_calendar_rated": "Đã đánh giá",
  "bs_livestream_calendar_rating_now": "Đánh giá ngay",
  "bs_livestream_calendar_reason_reject": "Lí do từ chối",
  "bs_livestream_calendar_reject": "Từ chối",
  "bs_livestream_calendar_reject_by": "Người từ chối",
  "bs_livestream_calendar_reject_reason": "Lý do từ chối",
  "bs_livestream_calendar_request_cancle_live_name": "Host \"{{name}}\" yêu cầu huỷ lịch live",
  "bs_livestream_calendar_request_change_time_name": "Host \"{{name}}\" yêu cầu thay đổi thông tin",
  "bs_livestream_calendar_skill": "Kỹ năng livestream",
  "bs_livestream_calendar_style": "Phong cách livestream",
  "bs_livestream_calendar_sub_brand": "Sub-brand",
  "bs_livestream_calendar_suit": "Trang phục",
  "bs_livestream_calendar_sure_agree": "Bạn muốn duyệt yêu cầu của Host?",
  "bs_livestream_calendar_sure_reject": "Bạn có chắc chắn muốn từ chối yêu cầu của Host?",
  "bs_livestream_calendar_target_gmv": "Mục tiêu GMV",
  "bs_livestream_calendar_time": "Đúng giờ",
  "bs_livestream_calendar_time_approve": "Thời gian duyệt",
  "bs_livestream_calendar_time_checkin": "Thời gian check-in",
  "bs_livestream_calendar_time_reject": "Thời gian từ chối",
  "bs_livestream_calendar_time_request": "Thời gian yêu cầu",
  "bs_livestream_calendar_title_card_detail": "Thông tin chi tiết",
  "bs_livestream_calendar_top_live": "BXH phiên live",
  "bs_livestream_calendar_top_revenue": "Chiến thần chốt đơn",
  "bs_livestream_calendar_view_detail": "Xem chi tiết",
  "bs_livestream_calendar_voice": "Giọng nói",
  "bs_livestream_calendar_waiting_approve": "Chờ duyệt",
  "bs_livestream_calendar_your_rating": "Đánh giá của bạn",
  "bs_livestream_change_request_failed": "Huỷ yêu cầu thất bại",
  "bs_livestream_change_request_required_failed": "Vui lòng nhập lí do từ chối",
  "bs_livestream_change_request_success": "Hủy yêu cầu thành công",
  "bs_livestream_column_data_import": "Kết quả tải lên",
  "bs_livestream_column_data_suggest": "Phiên live được gợi ý",
  "bs_livestream_column_data_system": "Phiên live trên hệ thống",
  "bs_livestream_cost_estimate": "Chi phí booking",
  "bs_livestream_empty_live_suggest": "Không có phiên live nào được gợi ý",
  "bs_livestream_error_date": "Vui lòng chọn ngày bắt đầu nhỏ hơn ngày kết thúc",
  "bs_livestream_error_host_checked": "Host đã check-in! Không thể hủy phiên live",
  "bs_livestream_error_host_not_ready": "Host đã có lịch livestream trong khung giờ đã chọn.",
  "bs_livestream_error_live_cancel": "Phiên live đã bị hủy! Vui lòng kiểm tra lại",
  "bs_livestream_error_live_room_used": "Phòng Live đã được sử dụng trong khung giờ đã chọn",
  "bs_livestream_label_agency": "Agency",
  "bs_livestream_label_campaign": "Chiến dịch",
  "bs_livestream_label_checkin_img_url": "Hoặc nhập đường dẫn ảnh",
  "bs_livestream_label_content": "Content",
  "bs_livestream_label_cost_estimate": "Chi phí",
  "bs_livestream_label_duration": "Thời lượng live",
  "bs_livestream_label_filter_campaign": "Chiến dịch",
  "bs_livestream_label_filter_date": "Thời gian live",
  "bs_livestream_label_filter_kol_host": "Host",
  "bs_livestream_label_filter_platform": "Nền tảng",
  "bs_livestream_label_gmv": "Mục tiêu GMV",
  "bs_livestream_label_host": "Host",
  "bs_livestream_label_live_id": "Livestream ID",
  "bs_livestream_label_live_room": "Phòng live",
  "bs_livestream_label_name": "Tên phiên Live",
  "bs_livestream_label_note": "Ghi chú",
  "bs_livestream_label_platform": "Nền tảng",
  "bs_livestream_label_sub_brand": "Sub-brand",
  "bs_livestream_label_time_live": "Thời gian live",
  "bs_livestream_label_url_format": "Vui lòng nhập đúng định dạng URL",
  "bs_livestream_label_url_placeholder": "Nhập đường đẫn",
  "bs_livestream_message_create_success": "Tạo mới phiên live thành công",
  "bs_livestream_message_duplicate_room_used": "Phòng live đã được sử dụng vào thời điểm này",
  "bs_livestream_message_edit_success": "Chỉnh sửa phiên live thành công",
  "bs_livestream_message_host_already_booked": "Host đã có lịch trình khác vào thời gian này",
  "bs_livestream_message_host_had_another_live": "Host đã có một phiên live khác vào lúc này",
  "bs_livestream_message_room_already_used": "Phòng đã được sử dụng cho một phiên live khác",
  "bs_livestream_not_checkin_yet": "Chưa check-in",
  "bs_livestream_performance_agency_title": "Agency",
  "bs_livestream_placeholder_sort_by": "Sắp xếp theo",
  "bs_livestream_report_title": "Livestream report",
  "bs_livestream_text_PIC": "PIC",
  "bs_livestream_text_action": "Thao tác",
  "bs_livestream_text_agv_viewers": "Avg. viewers",
  "bs_livestream_text_average_price": "Giá trị trung bình",
  "bs_livestream_text_avg_viewing_duration": "Avg. Viewing Duration",
  "bs_livestream_text_buyers": "Buyers",
  "bs_livestream_text_campaign": "Chiến dịch",
  "bs_livestream_text_check_info": "Thông tin Check-in",
  "bs_livestream_text_co": "C_O",
  "bs_livestream_text_comments": "Comments",
  "bs_livestream_text_ctr": "CTR",
  "bs_livestream_text_duration": "Thời lượng",
  "bs_livestream_text_end_time": "End Time",
  "bs_livestream_text_engagement_performance": "Hiệu quả tương tác",
  "bs_livestream_text_engagement_rate": "Engagement rate",
  "bs_livestream_text_gmv": "Mục tiêu GMV",
  "bs_livestream_text_gross_revenue": "Gross revenue",
  "bs_livestream_text_items_sold": "Sản phẩm đã bán",
  "bs_livestream_text_kol_host": "Host",
  "bs_livestream_text_likes": "Likes",
  "bs_livestream_text_live_info": "Thông tin phiên live",
  "bs_livestream_text_live_time": "Thời gian Live",
  "bs_livestream_text_livestream": "Livestream",
  "bs_livestream_text_livestream_id": "Livestream_id",
  "bs_livestream_text_minutes": "phút",
  "bs_livestream_text_new_follower": "New followers",
  "bs_livestream_text_new_followers": "Người theo dõi mới",
  "bs_livestream_text_note": "Ghi chú",
  "bs_livestream_text_orders": "Đơn hàng",
  "bs_livestream_text_orders_created": "Orders created",
  "bs_livestream_text_orders_paid_for": "Orders paid for",
  "bs_livestream_text_other_metrics": "Tiêu chí khác",
  "bs_livestream_text_pcu": "PCU",
  "bs_livestream_text_platform": "Nền tảng",
  "bs_livestream_text_product_clicks": "Product clicks",
  "bs_livestream_text_product_impressions": "Product impressions",
  "bs_livestream_text_reason": "Reason",
  "bs_livestream_text_sale_performance": "Hiệu quả bán hàng",
  "bs_livestream_text_show_gpm": "Show GPM",
  "bs_livestream_text_start_time": "Start time",
  "bs_livestream_text_viewers": "Viewers",
  "bs_livestream_text_views": "Views",
  "bs_livestream_text_watch_gpm": "Watch GPM",
  "bs_livestream_title_modal_cancel_session": "Bạn chắc chắn muốn hủy phiên live này?",
  "bs_livestream_title_table_list": "Danh sách",
  "bs_livestream_tooltip_watch_gpm": "Doanh thu trên 1000 lượt xem",
  "bs_living_area": "Khu vực",
  "bs_log_out": "Đăng Xuất",
  "bs_login": "Đăng nhập",
  "bs_login_account_erorr_verify": "Tài khoản chưa Active",
  "bs_login_account_err_locked": "Tài khoản của bạn đã bị khóa",
  "bs_login_failed_title": "Đăng Nhập Thất Bại.",
  "bs_login_follow_processing": "Theo dõi tiến độ nhanh chóng và dễ dàng",
  "bs_login_information_incorrect": "Thông tin đăng nhập không chính xác. Vui lòng thử lại!",
  "bs_login_successfully_title": "Bạn đã đăng nhập thành công!",
  "bs_macro": "Macro: từ 100.000 đến 1.000.000 followers",
  "bs_macro_not_des": "Macro",
  "bs_malaysia": "Malaysia",
  "bs_male": "Nam",
  "bs_manage_connect_connect_history": "Lịch sử kết nối",
  "bs_manage_connect_content_popup_connect": "Bạn chắc chắn muốn kết nối lại với cửa hàng? Dữ liệu trong giai đoạn dừng kết nối sẽ không được cập nhật lại",
  "bs_manage_connect_content_popup_disconnect": "Bạn có chắc muốn ngắt kết nối cửa hàng. Sau khi ngắt kết nối, dữ liệu của cửa hàng sẽ không được cập nhật.",
  "bs_manage_connect_title_card": "Danh sách cửa hàng",
  "bs_manage_list_host_title_page": "Manage list host",
  "bs_manage_video_list_title": "Danh sách yêu cầu",
  "bs_manage_video_title": "Danh sách video job",
  "bs_manual_connect_host_description_1": "Quản lý, vận hành và đánh giá phiên live dễ dàng, nhanh chóng hơn bằng cách hướng dẫn Host sử dụng hệ thống Passio.",
  "bs_manual_connect_host_description_2": "Host có thể theo dõi lịch live được xếp, check-in bắt đầu phiên, cập nhật doanh thu, gửi yêu cầu thay đổi lịch và theo dõi báo cáo hiệu suất của mình",
  "bs_manual_connect_host_title": "Hướng dẫn Host liên kết Passio",
  "bs_max_file_size": "Kích thước tệp tối đa: 4mb",
  "bs_media_type": "Hình thức",
  "bs_menu_affiliate_marketing_campaign": "Tiếp thị liên kết Shopee - Lazada",
  "bs_menu_booking_campaign": "UGC KOL",
  "bs_menu_booking_management": "Danh sách chiến dịch UGC",
  "bs_menu_booking_video_performent": "Hiệu quả Booking Video",
  "bs_menu_count_tiktok": "Bộ đếm video Tiktok",
  "bs_menu_dashboard": "Báo cáo tổng quan",
  "bs_menu_detail_koc_report": "Báo cáo chi tiết KOC",
  "bs_menu_detailed_report": "Báo cáo chiến dịch",
  "bs_menu_effective_approach": "Hiệu quả tiếp cận",
  "bs_menu_explore_tiktok_channel": "Khám phá kênh Tiktok",
  "bs_menu_explore_tiktok_shop": "Khám phá cửa hàng Tiktok",
  "bs_menu_general_report": "Báo cáo chung",
  "bs_menu_group_booking_video": "Booking Video",
  "bs_menu_group_creator": "Creator",
  "bs_menu_group_kol_live": "KOL Live",
  "bs_menu_group_live": "Brand Live",
  "bs_menu_group_order": "Order",
  "bs_menu_group_product": "Product",
  "bs_menu_group_video": "TAP Video",
  "bs_menu_host_livestream_calendar": "Lịch livestream",
  "bs_menu_host_overview_report": "Báo cáo tổng quan",
  "bs_menu_lib_tools": "Công cụ",
  "bs_menu_list_booking_video": "Danh sách Booking Video",
  "bs_menu_list_non_booking_video": "Danh sách TAP video",
  "bs_menu_list_video_job": "List Video Job",
  "bs_menu_list_video_package": "Purchase Video Package",
  "bs_menu_live_campaign": "Quản lý Brand live",
  "bs_menu_manage_list_kol": "Quản lý danh sách KOL booking",
  "bs_menu_product_management": "Quản lý sản phẩm",
  "bs_menu_report": "Báo cáo bán hàng",
  "bs_menu_result_campaign": "Kết quả nghiệm thu",
  "bs_menu_sale_performance": "Danh sách đơn hàng",
  "bs_menu_sales_report": "Báo cáo đơn hàng đa nền tảng",
  "bs_menu_setting_shop": "Thiết lập cửa hàng",
  "bs_menu_store_report": "Báo cáo shop",
  "bs_menu_system_management": "Quản trị hệ thống",
  "bs_menu_tiktok_affiliate": "Tiếp thị liên kết Tiktok",
  "bs_menu_video_jobs": "UGC Brand",
  "bs_message_added": "Liên kết thành công",
  "bs_message_approved": "Duyệt thành công",
  "bs_message_cancel_done": "Hủy thành công",
  "bs_message_create_success": "Tạo mới thành công",
  "bs_message_delete_campaign_success": "Xoá campagin thành công",
  "bs_message_delete_host_error": "Host không tồn tại",
  "bs_message_delete_host_success": "Xóa thành công",
  "bs_message_delete_success": "Xóa link thành công",
  "bs_message_edit_success": "Chỉnh sửa thành công",
  "bs_message_import_success": "Đã import thành công",
  "bs_message_rejected": "Đã từ chối yêu cầu nhận sản phẩm mẫu",
  "bs_message_success_crawl_campaign": "Đã đồng bộ thành công",
  "bs_message_success_update_shipping_info": "Cập nhật thông tin vận chuyển thành công",
  "bs_message_update_host_success": "Cập nhật thành công",
  "bs_message_update_product_fail": "Cập nhật thông tin sản phẩm thất bại. Vui lòng thử lại!",
  "bs_message_update_product_success": "Cập nhật thông tin sản phẩm thành công",
  "bs_micro": "Micro: từ 10.000 đến 100.000 followers",
  "bs_micro_not_des": "Micro",
  "bs_million": "Triệu",
  "bs_minutes": "phút",
  "bs_modal_title_detail_schedule": "Chi tiết lịch của {{ host }}",
  "bs_mom_and_baby": "Mẹ và bé",
  "bs_month": "Tháng",
  "bs_monthly_marketing_budget": "Ngân sách marketing hàng tháng",
  "bs_monthly_marketing_budget_placeholder": "Nhập ngân sách",
  "bs_monthly_revenue": "Doanh thu hàng tháng",
  "bs_monthly_revenue_placeholder": "Nhập doanh thu",
  "bs_nano": "Nano: dưới 10.000 followers",
  "bs_nano_not_des": "Nano",
  "bs_new_koc_joined": "KOC Mới Tham Gia",
  "bs_next": "Tiếp theo",
  "bs_nmv": "NMV",
  "bs_no_koc_in_campaigns": "Tạm thời chưa có KOC nào tham gia chiến dịch của bạn",
  "bs_no_requirement": "Không Có Yêu Cầu",
  "bs_no_v2": "Không",
  "bs_not_a_member_yet": "Not a member yet?",
  "bs_not_already_have_account": "Bạn chưa có tài khoản",
  "bs_not_requested": "Chưa đăng ký",
  "bs_not_violate": "Không Vi Phạm",
  "bs_notification_success_create_campaign": "Chọn KOC thành công",
  "bs_occur_time": "Thời gian diễn ra",
  "bs_ok_text_modal": "OK",
  "bs_on": "On",
  "bs_operation_tool": "Operation tool",
  "bs_optimize_performance": "Tối ưu thời gian, hiệu quả khi thực hiện báo cáo kết quả",
  "bs_option_all": "Tất cả",
  "bs_option_all_type": "Tất cả",
  "bs_option_crawled": "Đã Crawl",
  "bs_option_dont_have_sample_product": "Không có sản phẩm mẫu",
  "bs_option_have_sample_product": "Có sản phẩm mẫu",
  "bs_option_livestream": "Livestream",
  "bs_option_not_crawl": "Chưa crawl",
  "bs_option_select_empty_data_crawl": "Chưa có dữ liệu",
  "bs_option_select_not_empty_data_crawl": "Đã có dữ liệu",
  "bs_option_showcase": "Showcase",
  "bs_option_sort_by_collect_dec": "Lượt lưu bookmark giảm dần",
  "bs_option_sort_by_comment_dec": "Lượt Comment giảm dần",
  "bs_option_sort_by_default": "Mặc định",
  "bs_option_sort_by_like_dec": "Lượt thích giảm dần",
  "bs_option_sort_by_share_dec": "Lượt chia sẻ giảm dần",
  "bs_option_sort_by_view_dec": "Lượt xem giảm dần",
  "bs_option_video": "Video",
  "bs_options": "Option",
  "bs_order": "Đơn Hàng",
  "bs_order_id": "Mã đơn hàng",
  "bs_order_report_text_campaign": "Chiến dịch",
  "bs_order_report_text_creator": "Nhà sáng tạo",
  "bs_order_report_text_date": "Thời gian",
  "bs_order_report_text_platform": "Nền tảng",
  "bs_order_report_text_product": "Sản phẩm",
  "bs_order_report_text_shop": "Cửa hàng",
  "bs_order_report_text_type": "Loại nội dung",
  "bs_order_status_search_kol": "KOL",
  "bs_order_status_search_product": "Sản phẩm",
  "bs_order_status_search_title": "Trạng thái đơn hàng",
  "bs_orders": "Đơn hàng",
  "bs_orders_report_title_table": "Danh sách chi tiết",
  "bs_orders_summary": "Tổng Quan Đơn Hàng",
  "bs_other": "Khác",
  "bs_our_pricing": "Our Pricing",
  "bs_overview_live_forget_check_in": "Số phiên quên check in",
  "bs_overview_live_forget_check_in_rate": "Tỷ lệ check in muộn",
  "bs_overview_live_session_today": "Phiên live hôm nay",
  "bs_overview_live_session_upcoming": "Phiên live sắp diễn ra",
  "bs_overview_live_session_upcoming_tooltip": "Số phiên live sẽ diễn ra trong vòng 12 giờ tới",
  "bs_overview_live_session_upcoming_tooltip_v2": "Số phiên live sẽ diễn ra trong vòng 12 giờ tới",
  "bs_overview_live_total_live_hour": "Tổng giờ đã live",
  "bs_overview_live_total_live_hour_tooltip": "Tổng thời lượng của các phiên live đã kết thúc và đã check-in",
  "bs_overview_live_total_live_session_tooltip": "Gồm cả những phiên live chưa diễn ra",
  "bs_overview_live_total_order": "Tổng đơn hàng",
  "bs_overview_live_total_time": "Tổng giờ live",
  "bs_overview_live_total_time_tooltip": "Tổng thời lượng của tất cả các phiên live",
  "bs_overview_live_total_view": "Tổng lượt xem",
  "bs_overview_text_categories": "Categories:",
  "bs_overview_text_country": "Country:",
  "bs_overview_you_rate": "Bạn đánh giá",
  "bs_partners": "Partners",
  "bs_passio_guidebook": "Passio Guidebook",
  "bs_password": "Mật Khẩu",
  "bs_password_is_required": "Bắt buộc nhập \"Mật Khẩu\"",
  "bs_performace_report": "Performace Report",
  "bs_performance": "Hiệu Suất",
  "bs_performance_report": "Performance Report",
  "bs_philippine_branch": "Philippine Branch",
  "bs_philippines": "Philippin",
  "bs_photo": "Photo",
  "bs_placeholder_address": "Nhập địa chỉ",
  "bs_placeholder_area": "Ví dụ: Hồ Chí minh, Hà Nội, ...",
  "bs_placeholder_brand": "Your Brand",
  "bs_placeholder_create_category": "Nhập tên danh mục",
  "bs_placeholder_create_sub_brand": "Nhập tên sub-brand",
  "bs_placeholder_email": "Your Email",
  "bs_placeholder_enter_delivery_code": "Nhập mã vận đơn",
  "bs_placeholder_enter_delivery_unit": "Nhập đơn vị vận chuyển",
  "bs_placeholder_gmv": "Nhập GMV",
  "bs_placeholder_host_email": "Nhập email",
  "bs_placeholder_host_name": "Nhập tên host",
  "bs_placeholder_input_cfm_password": "Xác nhận mật khẩu",
  "bs_placeholder_input_code_host": "Nhâp mã liên kết",
  "bs_placeholder_input_date_rage": "Chọn khoảng thời gian",
  "bs_placeholder_input_email": "Email",
  "bs_placeholder_input_keyword": "Nhập Từ Khóa",
  "bs_placeholder_input_password": "Mật khẩu",
  "bs_placeholder_input_verifi_code": "Mã xác thực",
  "bs_placeholder_leave_mess": "Nhập lý do muốn đối lịch",
  "bs_placeholder_mess": "Leave us a message",
  "bs_placeholder_name": "Your Name",
  "bs_placeholder_name_live_room": "Nhập tên phòng",
  "bs_placeholder_new_input_password": "Mật khẩu mới",
  "bs_placeholder_phone_number": "Your Phone number",
  "bs_placeholder_quantity": "Nhập số lượng",
  "bs_placeholder_search_by_name_room": "Tìm kiếm theo tên phòng",
  "bs_placeholder_search_shop": "Tìm kiếm shop",
  "bs_placeholder_select_content_type": "Chọn loại content",
  "bs_placeholder_select_date": "Tuổi",
  "bs_placeholder_select_delivery_unit": "Chọn đơn vị vận chuyển",
  "bs_placeholder_select_shop": "Chọn shop",
  "bs_placeholder_store": "Your Website or Online store",
  "bs_placeholder_username_passio": "Nhập tên tài khoản",
  "bs_platform_search_title": "Nền tảng",
  "bs_please_enter_item": "Nhập Từ Khóa",
  "bs_please_select": "Vui lòng chọn",
  "bs_please_select_shop": "Chọn Cửa Hàng",
  "bs_popover_confirm_remove_sample_product": "Bạn chắc chắn muốn bỏ sản phẩm mẫu của sản phẩm",
  "bs_popup_confirm_content_request_ads_code": "Bạn muốn xem mã chạy quảng cáo của video này? Gửi yêu cầu và chúng tôi sẽ cập nhật thông tin tới bạn sớm nhất có thể.",
  "bs_popup_confirm_request_ads_code": "Gửi yêu cầu xem mã quảng cáo của video",
  "bs_popup_confirm_review_video": "Nhận xét video",
  "bs_preparing": "Đang chuẩn bị",
  "bs_price": "Giá dự kiến",
  "bs_privacy_policy": "Privacy Policy",
  "bs_product": "Sản phẩm",
  "bs_product_report_title": "SKU report",
  "bs_product_search_title": "Sản phẩm",
  "bs_promotion_approved": "Promotion Approved",
  "bs_promotion_item": "Sản phẩm ưu đãi",
  "bs_promotion_requests": "Gói ưu đãi",
  "bs_push_google_sheet_failed": "Push data to google sheet thất bại",
  "bs_radio_label_base_gmv": "Dựa theo GMV",
  "bs_radio_label_fixed": "Cố định",
  "bs_range_age_from": "Độ tuổi từ:",
  "bs_range_picker_placeholder_form_date": "Từ ngày",
  "bs_range_picker_placeholder_to_date": "đến ngày",
  "bs_reason": "Lí do",
  "bs_register": "Đăng ký",
  "bs_register_live_calendar": "Đăng kí lịch live",
  "bs_register_live_schedule": "Xếp lịch live",
  "bs_reject_by_seller": "Từ chối",
  "bs_remember_login": "Remember Login",
  "bs_report_last_7_days": "Báo Cáo 7 Ngày Gần Nhất",
  "bs_report_result": "KOL Report",
  "bs_report_result_placeholder_input_search": "Search by name, email...",
  "bs_report_result_placeholder_select_channels": "Select chanel",
  "bs_report_result_placeholder_select_type_followers": "Select KOL type",
  "bs_report_this_month": "Báo Cáo Tháng Này",
  "bs_request": "Yêu cầu",
  "bs_request_ads_code_failed": "Gửi yêu cầu xem mã quảng cáo của video thất bại",
  "bs_request_ads_code_successfully": "Gửi yêu cầu xem mã quảng cáo của video thành công",
  "bs_request_booking": "Yêu cầu booking",
  "bs_resend_verification_code": "Gửi lại trong",
  "bs_reset_search_button": "Đặt lại",
  "bs_result_campaign_placeholder_input_search": "Search theo từ khóa…",
  "bs_result_koc_name": "Tên KOC",
  "bs_result_link": "Link nghiệm thu",
  "bs_result_list": "Nghiệm thu chiến dịch",
  "bs_result_placeholder_choose_the_date": "Chọn ngày",
  "bs_result_placeholder_enter_number": "Nhập số nguyên dương",
  "bs_result_placeholder_enter_positive_number": "Nhập số dương",
  "bs_result_placeholder_import_search_campaign": "Chọn chiến dịch",
  "bs_result_placeholder_input_search_KOC": "Tìm kiếm KOC",
  "bs_result_placeholder_input_search_campaign": "Tìm kiếm chiến dịch",
  "bs_result_placeholder_input_search_text": "Tìm kiếm link",
  "bs_result_placeholder_result_content": "Nhập nội dung bài đăng",
  "bs_result_placeholder_select_filter_empty_data": "Trạng thái crawl data",
  "bs_result_placeholder_select_filter_violable": "Nội dung vi phạm",
  "bs_result_placeholder_select_search_KOC": "Tất cả KOC",
  "bs_result_placeholder_select_search_campaign": "Tất cả chiến dịch",
  "bs_result_placeholder_select_search_channel": "Tất cả kênh",
  "bs_result_title_modal_edit": "Chỉnh sửa thông tin nghiệm thu",
  "bs_results_list": "Danh Sách Nghiệm Thu",
  "bs_return_home": "Về trang chủ",
  "bs_revenue_button_content": "Explore more",
  "bs_revenue_content": "The Southeast Asia's most positive platform for Brand to engage Creator. Harnessing social selling, winning clients and growing revenue.",
  "bs_rows_per_page": "records per page",
  "bs_rows_showing": "Showing {{fromNumber}} to {{toNumber}} of {{totalNumber}} records",
  "bs_sale_amount": "Tổng giá trị",
  "bs_sale_performance": "Danh Sách Đơn Hàng",
  "bs_sale_performance_commission_title": "Commission",
  "bs_sale_performance_conversion_time_title": "Conversion Time",
  "bs_sale_performance_fulfilled_time_title": "Fulfilled Time",
  "bs_sale_performance_order_amount_title": "Order Amount",
  "bs_sale_performance_order_id_title": "Order ID",
  "bs_sale_performance_order_status_title": "Order status",
  "bs_sale_performance_placeholder_search": "Tìm kiếm theo tên người bán",
  "bs_sale_performance_platform_title": "Nền tảng",
  "bs_sale_performance_quantity_title": "Số lượng",
  "bs_sale_performance_seller_id_title": "Seller ID",
  "bs_sale_performance_seller_name_title": "Seller Name",
  "bs_sale_performance_sku_id_title": "SKU ID",
  "bs_sale_performance_sku_name_title": "SKU Name",
  "bs_sale_performance_traffic_source_title": "Traffic source",
  "bs_sale_performance_valid_status_title": "Valid status",
  "bs_save": "Lưu",
  "bs_save_list_kol": "Xác nhận",
  "bs_search": "Tìm kiếm",
  "bs_search_button": "Tìm kiếm",
  "bs_search_by_id_or_name_placeholder": "Theo ID livestream, Username",
  "bs_search_by_id_or_name_video_placeholder": "Tìm theo ID video, username tiktok",
  "bs_search_by_name_id_placeholder": "Tìm theo ID, tên",
  "bs_search_by_name_placeholder": "Tìm theo tên",
  "bs_search_campaign_bookings": "Tìm kiếm...",
  "bs_search_koc": "Tìm kiếm KOC",
  "bs_seen": "Seen",
  "bs_select_age_value": "Tuổi {{ageForm}} - {{ageTo}}",
  "bs_select_all_status": "Tất cả trạng thái",
  "bs_select_canceled_status": "Đã hủy",
  "bs_select_cancelled": "Đã hủy",
  "bs_select_completed": "Completed",
  "bs_select_completed_status": "Đã hoàn thành",
  "bs_select_option_platform_placeholder": "Chọn nền tảng",
  "bs_select_pending": "Đang xử lý",
  "bs_select_pending_status": "Đang xử lý",
  "bs_select_platform_facebook": "Facebook",
  "bs_select_platform_instagram": "Instagram",
  "bs_select_platform_lazada": "Lazada",
  "bs_select_platform_other": "Khác",
  "bs_select_platform_shopee": "Shopee",
  "bs_select_platform_tiki": "Tiki",
  "bs_select_platform_tiktok": "Tiktok",
  "bs_select_platform_website": "Website",
  "bs_select_status_campaign_placeholder": "Chọn trạng thái",
  "bs_send": "SEND",
  "bs_send_mail_campaign_helper_title": "Gửi email cho các Creator bạn đã chọn để giới thiệu về chiến dịch này.",
  "bs_send_mail_campaign_input_brand_description": "Mô tả brands & sản phẩm",
  "bs_send_mail_campaign_input_placeholder_brand_description": "Nhập mô tả brands & sản phẩm",
  "bs_send_mail_campaign_input_placeholder_request_content": "Nhập mô tả yêu cầu",
  "bs_send_mail_campaign_input_request_content": "Mô tả yêu cầu",
  "bs_send_mail_campaign_title": "Gửi email chiến dịch",
  "bs_send_verification_code_success": "Mã xác nhận đã được gửi tới email của bạn",
  "bs_sex": "Giới tính",
  "bs_sex_is_required": "Giới tính không được bỏ trống",
  "bs_shipping": "Đang vận chuyển",
  "bs_shop_name": "Tên shop",
  "bs_short_video": "Short video",
  "bs_showcase": "Showcase",
  "bs_sign_in": "Đăng Nhập",
  "bs_sign_in_agency": "Đăng nhập",
  "bs_sign_in_host": "Đăng nhập",
  "bs_sign_up": "Đăng Ký",
  "bs_sign_up_failed_title": "Đăng ký thất bại",
  "bs_sign_up_information_incorrect": "Thông tin đăng ký không chính xác",
  "bs_sign_up_input_verify_code_required": "Vui lòng nhập mã xác thực",
  "bs_sign_up_success_title": "Đăng kí tài khoản thành công",
  "bs_sign_up_text": "Đăng ký",
  "bs_singapore": "Singapore",
  "bs_singapore_branch": "Singapore Branch",
  "bs_sku": "SKU",
  "bs_sku_is_required": "Vui lòng lựa chọn SKU",
  "bs_sku_management": "SKU Management",
  "bs_sku_management_placeholder_search": "Theo mã, tên",
  "bs_sku_management_placeholder_select_sub_brand": "Chọn thương hiệu phụ",
  "bs_sku_management_sku_categories": "Danh mục",
  "bs_sku_management_sku_id": "ID",
  "bs_sku_management_sku_name": "Sản phẩm",
  "bs_sku_management_text_loading_import": "Đang import, vui lòng chờ!",
  "bs_sku_report_detail_content": "Chi tiết nội dung",
  "bs_social_media": "Social media",
  "bs_social_media_is_required": "Social media không được bỏ trống",
  "bs_social_network_channel": "Kênh Mạng Xã Hội",
  "bs_social_performance": "Social Performance",
  "bs_social_report_title_most_activated": "Most Activated KOC",
  "bs_social_report_title_most_engagement": "Most Engagement KOC",
  "bs_social_report_title_most_post_comment": "Most post comment",
  "bs_social_report_title_most_video_view": "Most Video View",
  "bs_some_thing_wrong": "Đã có lỗi xảy ra!",
  "bs_sort_by_agency_high_rating": "Agency đánh giá cao",
  "bs_sort_by_agency_rating": "Agency đánh giá",
  "bs_sort_by_brand_high_rating": "Brand đánh giá cao",
  "bs_sort_by_brand_rating": "Nhãn hàng đánh giá",
  "bs_sort_by_duration": "Thời lượng đã live",
  "bs_sort_by_live": "Số phiên hoàn thành",
  "bs_sort_by_order": "Tổng đơn hàng",
  "bs_sort_by_order_average": "Đơn hàng / Phiên",
  "bs_sort_by_order_count": "Đơn hàng cao nhất",
  "bs_sort_by_paid_amount": "Doanh thu cao nhất",
  "bs_sort_by_revenue": "Tổng doanh thu",
  "bs_sort_by_revenue_average": "Doanh thu / Phiên",
  "bs_sort_by_revenue_per_hour": "Doanh thu / giờ live",
  "bs_sort_by_room_count": "Phiên live nhiều nhất",
  "bs_sort_by_total_post": "Bài đăng nhiều nhất",
  "bs_sort_by_video_count": "Video nhiều nhất",
  "bs_sort_by_view_count": "Lượt xem cao nhất",
  "bs_sport": "Thể thao ",
  "bs_stat_title_comment": "Comments",
  "bs_stat_title_diggs": "Diggs",
  "bs_stat_title_like": "Likes",
  "bs_stat_title_play": "Plays",
  "bs_stat_title_reach": "Potential Reach",
  "bs_stat_title_reaction": "Reactions",
  "bs_stat_title_save": "Saves",
  "bs_stat_title_share": "Shares",
  "bs_stat_title_views": "Views",
  "bs_status": "Trạng thái",
  "bs_status_campaign": "Trạng thái chiến dịch",
  "bs_store": "Cửa Hàng",
  "bs_store_name": "Store Name",
  "bs_store_reports_title": "Store reports",
  "bs_sub_brand": "Sub-brand",
  "bs_sub_brand_is_required": "Vui lòng chọn Sub-brand",
  "bs_sub_brand_report_title": "Sub-brand Report",
  "bs_sub_brands": "Sub-brand",
  "bs_sub_title_modal_map_data_live": "Tệp tải lên chứa các kết quả chưa được gán với phiên live trên hệ thống. Hãy chọn phiên live tương ứng với kết quả của bạn.",
  "bs_submit_campagin_error_file_length": "Dung lượng tệp tối đa: 30MB",
  "bs_submit_campagin_error_file_type": "Định dạng hỗ trợ: jpg, jpeg, gif, png, bmp, webp, HEIC",
  "bs_submit_campagin_send_campagin_result": "Gửi kết quả nghiệm thu",
  "bs_submit_campagin_submit_error": "Gửi nghiệm thu thất bại",
  "bs_submit_campagin_text_button_upload": "Tải lên",
  "bs_submit_campaign_button_resend_submit": "Gửi lại nghiệm thu",
  "bs_submit_campaign_button_send_result": "Gửi nghiệm thu",
  "bs_submit_campaign_category_label": "Category",
  "bs_submit_campaign_help_text_no_image": "Nhấn nút “Tải lên” để thêm kết quả của bạn nhé",
  "bs_submit_campaign_input_attachment": "Tệp đính kèm",
  "bs_submit_campaign_input_attachment_description": "Dung lượng tệp tối đa: 30MB. Định dạng hỗ trợ: jpg, jpeg, gif, png, bmp, webp, HEIC",
  "bs_submit_campaign_input_channel_required": "Vui lòng nhập vào link channel",
  "bs_submit_campaign_input_comment_count": "Bình luận",
  "bs_submit_campaign_input_link_placeholder": "Nhập đường link",
  "bs_submit_campaign_input_link_required": "Vui lòng nhập link submit",
  "bs_submit_campaign_input_link_submit": "Link Submit",
  "bs_submit_campaign_input_number_placeholder": "Nhập số",
  "bs_submit_campaign_input_reaction_count": "Reaction",
  "bs_submit_campaign_input_view_count": "Lượt xem",
  "bs_submit_campaign_no_result_photo_yet": "Chưa có ảnh kết quả nào",
  "bs_submit_campaign_social_label": "Social Media",
  "bs_submit_campaign_text_checkbox_interaction": "Thêm chỉ số hiệu quả tương tác",
  "bs_submit_campaign_text_submit_success": "Submit kết quả thành công",
  "bs_submit_campaign_time_label": "Thời gian",
  "bs_submit_campaign_title_page": "Submit Result",
  "bs_submit_result_link": "Submit Result Link",
  "bs_submit_success_title": "Gửi thông tin thành công",
  "bs_submitted_time": "Thời gian gửi nghiệm thu",
  "bs_suggest_tooltip_gmv": "Doanh thu ước tính của Creator trên tiktok trong 30 ngày gần nhất",
  "bs_suggest_tooltip_post_rate": "Những nhà sáng tạo không có tỷ lệ đăng bài là những Creator chưa từng chạy affiliate trước đó",
  "bs_suggested_list_placeholder_search_user": "Tìm theo tên creator",
  "bs_suggested_list_title_table": "Nhà sáng tạo đăng ký nhận sản phẩm mẫu",
  "bs_summary": "Tổng quan",
  "bs_support_format": "Định dạng hỗ trợ: jpg, jpeg, png, bmp, heic",
  "bs_system_created": "System-created",
  "bs_table_select_total_item": "Đã chọn {{num}} mục",
  "bs_tabs_map_video": "Chưa có phiên live",
  "bs_tabs_pass_video": "Đã có phiên live",
  "bs_tabs_title_info": "Thông tin",
  "bs_tabs_title_performance": "Hiệu suất",
  "bs_tap_inventory_count": "Hàng tồn kho",
  "bs_tap_label_content_type": "Loại nội dung",
  "bs_tap_sold_count": "Đã bán",
  "bs_tap_suggest_content_multiple_popup_approve": "Bạn muốn duyệt {{num}} yêu cầu nhận sản phẩm mẫu?",
  "bs_tap_suggest_content_multiple_popup_reject": "Bạn muốn từ chối {{num}} yêu cầu nhận sản phẩm mẫu?",
  "bs_tap_suggest_content_single_popup_approve": "Bạn muốn duyệt yêu cầu nhận sản phẩm mẫu của nhà sáng tạo?",
  "bs_tap_suggest_content_single_popup_reject": "Bạn muốn từ chối yêu cầu nhận sản phẩm mẫu của nhà sáng tạo?",
  "bs_tap_suggest_title_popup_approve": "Phê duyệt nhà sáng tạo nhận sản phẩm mẫu",
  "bs_tap_suggest_title_popup_reject": "Từ chối nhà sáng tạo nhận sản phẩm mẫu",
  "bs_tap_text_update_multiple_sample": "Bật sản phẩm mẫu cho {{num}} sản phẩm đã chọn",
  "bs_tap_total_commission_rate": "Tổng tỷ lệ hoa hồng",
  "bs_terms_of_use": "Terms Of Use",
  "bs_text_actual": "Thực tế",
  "bs_text_add_new_slot": "Thêm khung giờ mới",
  "bs_text_add_range_time": "Thêm khoảng",
  "bs_text_age_form": "Tuổi từ",
  "bs_text_age_to": "đến",
  "bs_text_all_day": "Cả ngày",
  "bs_text_all_day_tooltip": "Có thể xếp lịch vào bất kỳ khung giờ nào",
  "bs_text_avg_gmv": "Average GMV",
  "bs_text_busy": "Bận",
  "bs_text_cancel_live": "Huỷ phiên live",
  "bs_text_create_new_live_session": "Tạo phiên live",
  "bs_text_custom": "Chọn ngày",
  "bs_text_done": "Xong",
  "bs_text_email": "Email",
  "bs_text_empty_live_in_month": "Chưa có phiên live nào được tạo trong tháng",
  "bs_text_empty_not_select_host": "Vui lòng chọn 1 host để xem lịch live tháng {{month}} của host đó",
  "bs_text_erorr_time": "Thời gian bắt đầu phải nhỏ hơn kết thúc",
  "bs_text_err_input_email": "Vui lòng nhập địa chỉ email",
  "bs_text_err_input_password": "Vui lòng nhập mật khẩu",
  "bs_text_err_min_length_input_password": "Vui lòng nhập ít nhất 8 ký tự",
  "bs_text_err_required_input_verifi_code": "Vui lòng nhập mã xác thực",
  "bs_text_err_type_input_email": "Vui lòng nhập đúng định dạng Email",
  "bs_text_estimate": "Dự kiến",
  "bs_text_estimate_GMV": "GMV ước tính",
  "bs_text_every_week": "Hàng tuần",
  "bs_text_everyday": "Hàng ngày",
  "bs_text_follower": "Người theo dõi",
  "bs_text_forgot_password": "Quên mật khẩu",
  "bs_text_forgot_password_des": "Bạn chỉ được thực hiện tối đa 5 lượt quên mật khẩu trong 1 ngày, mỗi lần cách nhau tối thiểu 60 giây",
  "bs_text_gmv_last_3_month": "GMV 3 tháng gần nhất",
  "bs_text_help_text_custom": "Lịch đăng ký sẽ áp dụng cho những ngày được chọn trong lịch",
  "bs_text_help_text_everyday": "Lịch đăng ký sẽ lặp lại hàng ngày từ ngày {{date}} đến ngày",
  "bs_text_help_text_weekly": "Lịch đăng ký sẽ lặp lại vào",
  "bs_text_help_text_weekly_2": "hàng tuần từ ngày {{date}} ",
  "bs_text_help_text_weekly_3": "đến ngày {{date}}",
  "bs_text_host_email": "Email host",
  "bs_text_host_name": "Host Name",
  "bs_text_label_done": "Đã thực hiện",
  "bs_text_linked": "Đã kết nối",
  "bs_text_linked_account": "Tài khoản liên kết",
  "bs_text_list_host": "Danh sách host",
  "bs_text_list_room": "Quản lý phòng live",
  "bs_text_live_infomation": "Đổi thông tin",
  "bs_text_liveseeson_of_host": "Số phiên đã tạo của",
  "bs_text_need_to_do": "Cần thực hiện",
  "bs_text_no": "Không",
  "bs_text_not_connect_shop_code": "Chưa kết nối cửa hàng",
  "bs_text_not_linked": "Chưa kết nối",
  "bs_text_not_match_cfm_password": "Mật khẩu không khớp!",
  "bs_text_note_placeholder": "Nhập nội dung ghi chú",
  "bs_text_number_of_live": "Số phiên",
  "bs_text_number_of_video": "Số lượng video trong các campaign booking",
  "bs_text_operating_status": "Trạng thái hoạt động",
  "bs_text_passio_link": "Liên kết Passio",
  "bs_text_pending_request": "Chờ duyệt",
  "bs_text_register_live_month": "Đăng ký giờ live tháng",
  "bs_text_register_live_month_description": "Đăng ký thời gian bạn có thể livestream để nhãn hàng xếp lịch phù hợp với bạn",
  "bs_text_register_live_month_text": "Lịch live tháng",
  "bs_text_remain": "Còn lại",
  "bs_text_repeat": "Lặp lại",
  "bs_text_required": "Thông tin bắt buộc",
  "bs_text_required_input_cfm_password": "Vui lòng xác nhận mật khẩu",
  "bs_text_revenue_from_order": "Doanh thu và số lượng các đơn hàng TAP",
  "bs_text_sample_product_quantity": "Số lượng sản phẩm mẫu",
  "bs_text_scheduled": "Đã có lịch",
  "bs_text_search_results": "Kết quả tìm kiếm",
  "bs_text_seller_center": "Trung tâm người bán hàng",
  "bs_text_settings": "Cài đặt",
  "bs_text_shop_code": "Mã cửa hàng",
  "bs_text_shop_information": "Thông tin cửa hàng",
  "bs_text_slot_live": "Khung giờ live",
  "bs_text_step": "Bước {{step}}",
  "bs_text_sub_brand": "Sub brand",
  "bs_text_target": "Mục tiêu",
  "bs_text_target_lable_lowercase": "mục tiêu",
  "bs_text_time_end": "Kết thúc",
  "bs_text_time_range": "Khoảng thời gian",
  "bs_text_time_range_tooltip": "Chỉ xếp lịch trong khoảng thời gian bạn chọn",
  "bs_text_time_slot": "Khung giờ",
  "bs_text_time_start": "Bắt đầu",
  "bs_text_total_hour": "Tổng số giờ",
  "bs_text_total_session": "Tổng số phiên",
  "bs_text_total_session_created": "Tổng số phiên đã tạo ngày",
  "bs_text_upcoming": "Sắp diễn ra",
  "bs_text_update_failed": "Cập nhật thất bại",
  "bs_text_update_soon": "Chúng tôi sẽ sớm cập nhật tính năng này",
  "bs_text_update_success": "Cập nhật thành công",
  "bs_text_upload_img": "Thêm ảnh",
  "bs_text_view_more": "Xem thêm",
  "bs_textarea_video_review_placeholder": "Nhập nội dung nhận xét",
  "bs_th": "Thái Lan",
  "bs_thailand": "Thái lan",
  "bs_thailand_branch": "Thailand Branch",
  "bs_this_month": "Tháng này",
  "bs_thousand": "Nghìn",
  "bs_tiktok": "Tiktok",
  "bs_tiktok_aff_avg_order_value": "Đơn giá bình quân",
  "bs_tiktok_aff_cancelled_rate": "Tỷ lệ hủy",
  "bs_tiktok_aff_connect": "Kết nối cửa hàng Tiktok",
  "bs_tiktok_aff_creator_connect": "Creator hợp tác",
  "bs_tiktok_aff_detail_sku": "Chi tiết cộng tác",
  "bs_tiktok_aff_list_content": "List content",
  "bs_tiktok_aff_livestream_host": "Host",
  "bs_tiktok_aff_livestream_id": "ID phiên live",
  "bs_tiktok_aff_livestream_monitor": "Phiên live trực tiếp",
  "bs_tiktok_aff_livestream_sesson": "Phiên live",
  "bs_tiktok_aff_livestream_tap": "Danh sách TAP livestream",
  "bs_tiktok_aff_manage_connect_shop": "Thiết lập Tiktok Shop",
  "bs_tiktok_aff_no_access_view": "Rất tiếc, không thể xem báo cáo của phiên live này do phiên live không thông qua TAP Campaign",
  "bs_tiktok_aff_no_livestream": "Không có phiên live nào đang diễn ra",
  "bs_tiktok_aff_order_cancelled": "Đơn hàng đã hủy",
  "bs_tiktok_aff_orders_report": "Báo cáo đơn hàng TAP",
  "bs_tiktok_aff_suggested": "KOL đăng ký",
  "bs_tiktok_aff_tap_sku": "Danh sách sản phẩm đăng ký TAP",
  "bs_tiktok_aff_trend_chart_index": "Biểu đồ xu hướng mỗi chỉ số",
  "bs_tiktok_affiliate_list_sku": "Hiệu suất sản phẩm TAP ",
  "bs_tiktok_affiliate_list_suggested": "Yêu cầu SP mẫu TAP",
  "bs_tiktok_connect_button_submit": "Kết nối ngay",
  "bs_tiktok_connect_description": "Để theo dõi các chiến dịch TAP của Ecomobi mà bạn đã đăng ký",
  "bs_tiktok_connect_description_step_1_after": "của tiktok và click vào",
  "bs_tiktok_connect_description_step_1_before": "Truy cập",
  "bs_tiktok_connect_description_step_2": "Click vào menu",
  "bs_tiktok_connect_description_step_3": "Copy",
  "bs_tiktok_connect_instruction_title": "Hướng dẫn lấy mã cửa hàng",
  "bs_tiktok_connect_message_error": "Shop chưa đăng ký chiến dịch TAP của Ecomobi trên tiktok hoặc mã shop không chính xác. Vui lòng kiểm tra lại",
  "bs_tiktok_connect_message_error_214": "Cửa hàng đã được kết nối với hệ thống",
  "bs_tiktok_connect_message_success": "Đã kết nối cửa hàng tiktokshop thành công",
  "bs_tiktok_connect_placeholder_shop_code": "Nhập mã cửa hàng",
  "bs_tiktok_connect_title": "Kết nối tài khoản của bạn với tiktokshop",
  "bs_tiktok_counter": "Bộ đếm video tiktok",
  "bs_tiktok_counter_button_export": "Xuất file",
  "bs_tiktok_counter_button_filter": "Bộ lọc",
  "bs_tiktok_counter_button_search": "Tìm kiếm",
  "bs_tiktok_counter_placeholder_search": "Theo từ khóa",
  "bs_tiktok_counter_table_title_actions": "Thao tác",
  "bs_tiktok_counter_table_title_author": "Tên KOL",
  "bs_tiktok_counter_table_title_comments": "Bình luận",
  "bs_tiktok_counter_table_title_description": "Mô tả",
  "bs_tiktok_counter_table_title_likes": "Lượt thích",
  "bs_tiktok_counter_table_title_link": "Link Submit",
  "bs_tiktok_counter_table_title_plays": "Lượt xem",
  "bs_tiktok_counter_table_title_reach": "Tiếp cận",
  "bs_tiktok_counter_table_title_saves": "Lượt lưu vào Bookmark",
  "bs_tiktok_counter_table_title_shares": "Chia sẻ",
  "bs_tiktok_counter_table_title_update_at": "Lần cuối cập nhật",
  "bs_tiktok_counter_text_loading_import": "Đang import...",
  "bs_tiktok_counter_title_filter_more": "Bộ lọc thêm",
  "bs_tiktok_counter_title_modal_edit": "Chỉnh sửa",
  "bs_tiktok_discovery": "Khám phá kênh Tiktok",
  "bs_tiktok_discovery_average_comments_count": "Trung bình bình luận",
  "bs_tiktok_discovery_average_digg_count": "Trung bình thích",
  "bs_tiktok_discovery_average_plays_count": "Trung bình xem",
  "bs_tiktok_discovery_average_saves_count": "Trung bình lưu Bookmark",
  "bs_tiktok_discovery_average_shares_count": "Trung bình chia sẻ",
  "bs_tiktok_discovery_placeholder_search": "Theo tài khoản, biệt danh",
  "bs_tiktok_discovery_sort_by": "Sắp xếp theo",
  "bs_tiktok_discovery_table_title_bioLink": "Bio Link",
  "bs_tiktok_discovery_table_title_channel_description": "Mô tả kênh",
  "bs_tiktok_discovery_table_title_digg_count": "Thích",
  "bs_tiktok_discovery_table_title_follower_count": "Người theo dõi",
  "bs_tiktok_discovery_table_title_following_count": "Đang theo dõi",
  "bs_tiktok_discovery_table_title_link_channel": "Link kênh",
  "bs_tiktok_discovery_table_title_nickname": "Nickname",
  "bs_tiktok_discovery_table_title_plays": "Xem",
  "bs_tiktok_discovery_table_title_saves": "Lưu Bookmark",
  "bs_tiktok_discovery_table_title_username": "Username",
  "bs_tiktok_discovery_table_title_video_count": "Video",
  "bs_tiktok_discovery_title_modal_view": "Thông tin chi tiết",
  "bs_tiktok_manage_connect": "Thiết lập Tiktok Shop",
  "bs_tiktok_shop_error_connect": "Cửa hàng đang kết nối với nhãn hàng khác. Vui lòng kiểm tra lại",
  "bs_tiktok_shop_success_connect": "Kết nối cửa hàng thành công",
  "bs_tiktok_text_post_rate": "Tỷ lệ đăng bài",
  "bs_time_end_field_required": "Vui lòng chọn thời gian kết thúc",
  "bs_time_end_label": "Kết thúc",
  "bs_time_start_field_required": "Vui lòng chọn thời gian bắt đầu",
  "bs_time_start_label": "Bắt đầu",
  "bs_time_start_must_before_end_time": "Thời gian bắt đầu phải trước thời gian kết thúc",
  "bs_timeline": "Timeline",
  "bs_title_all_live_session_report": "Báo cáo tất cả phiên live của cửa hàng",
  "bs_title_card_table_list_order": "Danh sách chi tiết ({{total}})",
  "bs_title_card_table_top_product_live": "Top sản phẩm",
  "bs_title_connect_store_to_passio": "Kết nối cửa hàng với Passio",
  "bs_title_connect_to_explore_detail_report": "Kết nối của hàng với Passio để đồng bộ thông tin báo cáo",
  "bs_title_create_category": "Thêm mới danh mục",
  "bs_title_create_sub_brand": "Thêm mới sub-brand",
  "bs_title_creator_number": "Số lượng Creator",
  "bs_title_detail_host": "Chi tiết host",
  "bs_title_download_template_import": "File mẫu",
  "bs_title_download_template_import_with_type": "File mẫu {{type}}",
  "bs_title_drag_file": "Kéo thả hoặc nhấn chọn để tải tệp lên",
  "bs_title_empty_data_search": "Không có kết quả tìm kiếm",
  "bs_title_field_create_category": "Tên danh mục",
  "bs_title_field_create_sub_brand": "Tên sub-brand",
  "bs_title_list_room": "Danh sách phòng live",
  "bs_title_live_session_report": "Báo cáo phiên live của",
  "bs_title_modal_connect_host": "Liên kết nhãn hàng",
  "bs_title_modal_import_campaign": "Import Campaign",
  "bs_title_modal_import_live_agency": "Tải lên phiên live chỉnh sửa",
  "bs_title_modal_import_live_result": "Tải lên kết quả Livestream",
  "bs_title_modal_import_livestream_session": "Tải lên phiên live mới",
  "bs_title_modal_import_result": "Import result",
  "bs_title_modal_import_tiktok_counter": "Tải lên",
  "bs_title_modal_list_kol_selected": "Chỉnh sửa",
  "bs_title_modal_map_data_live": "Chọn phiên live cho kết quả tải lên",
  "bs_title_multi_live_session_report": "Báo cáo {{lives}} phiên live của cửa hàng ",
  "bs_title_popup_confirm_delete": "Xóa link?",
  "bs_title_popup_confirm_delete_campaign": "Xóa mục này?",
  "bs_title_popup_confirm_delete_sku": "Xóa mục?",
  "bs_title_search_air_time": "Thời gian Air",
  "bs_title_top_brand": "Top Brand",
  "bs_title_top_channel": "Top kênh",
  "bs_title_top_influencer": "Top Influencer",
  "bs_title_top_koc": "Top KOC",
  "bs_title_top_result": "Top 10 Result",
  "bs_title_top_sale_performence": "Top Sale Performance",
  "bs_title_type_import": "Nền tảng",
  "bs_to": "đến",
  "bs_to_review": "Review",
  "bs_tool_tip_for_promotion_approved": "Tổng số sản phẩm đã duyệt/ Tổng số sản phẩm của gói ưu đãi",
  "bs_tooltip_colum_gmv_waiting": "GMV đang chờ = Tổng GMV của những đơn Đã hoàn thành và Đang xử lý",
  "bs_tooltip_view_collaborated_creators": "Số nhà sáng tạo đã thêm sản phẩm vào showcase.",
  "bs_tooltip_view_copy_ads_code": "Sao chép mã",
  "bs_tooltip_view_copy_request_ads_code": "Sao chép mã quảng cáo",
  "bs_tooltip_view_detail": "Xem chi tiết",
  "bs_tooltip_view_estimate_cost": "Chi phí ước tính gồm cả chi phí booking và chi phí hoa hồng (nếu có)",
  "bs_tooltip_view_paid_orders": "Số đơn hàng đã thanh toán từ các link TAP của sản phẩm, bao gồm cả những đơn trả lại và hoàn tiền.",
  "bs_tooltip_view_posted_creators": "Số nhà sáng tạo đã đăng bài cho sản phẩm.",
  "bs_tooltip_view_revenued_creators": "Số nhà sáng tạo đã phát sinh đơn hàng cho sản phẩm.",
  "bs_tooltip_view_roas": "ROAS = NMV / Ngân sách",
  "bs_tooltip_view_settlemented_orders": "Số đơn hàng đã thanh toán từ các link TAP của sản phẩm, không bao gồm những đơn trả lại và hoàn tiền.",
  "bs_tooltip_view_sum_campaigns_filter": "Số chiến dịch có video thỏa mãn bộ lọc",
  "bs_tooltip_view_sum_creators_filter": "Số nhà sáng tạo có video thỏa mãn bộ lọc",
  "bs_tooltip_view_sum_paid_orders": "Tổng giá trị của những đơn hàng đã thanh toán.",
  "bs_tooltip_view_sum_settlemented_orders": "Tổng giá trị của những đơn hàng đã quyết toán.",
  "bs_tooltip_view_sum_successfully_orders": "Tổng doanh thu của những đơn hàng thành công",
  "bs_tooltip_view_total_budget": "Ngân sách = Chi phí booking + Chi phí quảng cáo + Hoa hồng",
  "bs_top": "Top: trên 1.000.000 followers",
  "bs_top_creator": "Top nhà sáng tạo",
  "bs_top_not_des": "Top",
  "bs_top_result_button_view_all": "View All",
  "bs_top_selling_product": "Top sản phẩm bán chạy",
  "bs_top_video_title": "BXH video",
  "bs_total": "Tổng",
  "bs_total_budget": "Tổng ngân sách",
  "bs_total_budget_is_required": "Tổng ngân sách không được bỏ trống",
  "bs_total_campaign": "Tổng chiến dịch",
  "bs_total_follower": " Số lượng followers",
  "bs_total_follower_is_required": " Số lượng followers không được bỏ trống",
  "bs_total_followers": "Followers",
  "bs_total_item": "Total Item",
  "bs_total_items_numer": "Tổng {{number}} bản ghi",
  "bs_tour_description_step_1": "Kết nối tài khoản của bạn với cửa hàng trên tiktokshop để đồng bộ dữ liệu Tiktok Affiliate và sử dụng các tính năng TAP",
  "bs_tour_description_step_10": "Quản lý danh sách tất cả các đơn hàng của bạn từ mọi nền tảng",
  "bs_tour_description_step_2": "Theo dõi trực quan về tiến độ hoàn thành và hiệu quả hoạt động bán hàng của bạn",
  "bs_tour_description_step_3": "Quản lý danh sách và duyệt các yêu cầu nhận sản phẩm mẫu của nhà sáng tạo đối với các sản phẩm mà bạn đang chạy Tiktok Affiliate với Ecomobi",
  "bs_tour_description_step_4": "Quản lý danh sách các sản phẩm chạy Tiktok Affiliate của bạn. Tại đây bạn có thể theo dõi số lượng sản phẩm mẫu của sản phẩm và xem hiệu suất bán hàng của các nhà sáng tạo đối với sản phẩm tương ứng.",
  "bs_tour_description_step_5": "Theo dõi hiệu suất các phiên livestream trên kênh của bạn. Hệ thống sẽ giúp bạn dễ dàng theo dõi mức độ hoàn thành của host; tìm kiếm host và phiên live có hiệu suất tốt để ra quyết định chính xác hơn",
  "bs_tour_description_step_6": " Theo dõi đơn hàng trong các phiên live đang diễn ra trên kênh của Nhà sáng tạo có gán link sản phẩm TAP của bạn",
  "bs_tour_description_step_7": "Quản lý danh sách các phiên live trên kênh của bạn. Tất cả nghiệp vụ liên quan đến livestream như xếp lịch cho host, theo dõi tiến độ, tải lên kết quả phiên live, đánh giá phiên live đều được thực hiện tại đây",
  "bs_tour_description_step_8": "Theo dõi báo cáo tổng quan về tất cả đơn hàng của bạn. Dễ dàng tìm kiếm những sản phẩm, dòng sản phẩm đang bán chạy hay những KOL, bài đăng đang có hiệu suất cao",
  "bs_tour_description_step_9": "Theo dõi báo cáo các đơn hàng của sản phẩm chạy Tiktok Affiliate theo nhiều chiều khác nhau, giúp bạn phân tích dữ liệu sâu và đưa ra đánh giá chính xác",
  "bs_tour_description_step_manual": "Nhấn \"Bắt đầu\" để xem mô tả các tính năng trên hệ thống",
  "bs_tour_description_step_welcome": "Xin cảm ơn và chào mừng bạn đến với nền tảng hỗ trợ các nhãn hàng quản lý và vận hành các nghiệp vụ Marketing, Bán hàng trên các nền tảng mạng xã hội và sàn thương mại điện tử. Cùng khám phá ngay bằng cách nhấn \"Bắt đầu\" nhé!",
  "bs_tour_title_step_1": "Kết nối cửa hàng ",
  "bs_tour_title_step_10": "Quản lý danh sách đơn hàng",
  "bs_tour_title_step_2": "Báo cáo tổng quan",
  "bs_tour_title_step_3": "Yêu cầu nhận sản phẩm mẫu",
  "bs_tour_title_step_4": "Báo cáo hiệu suất sản phẩm TAP",
  "bs_tour_title_step_5": "Báo cáo hiệu quả livestream",
  "bs_tour_title_step_6": "Theo dõi phiên live trực tiếp",
  "bs_tour_title_step_7": "Quản lý lịch live stream",
  "bs_tour_title_step_8": "Báo cáo đơn hàng đa nền tảng",
  "bs_tour_title_step_9": "Báo cáo đơn hàng TAP",
  "bs_tour_title_step_manual": "Product Tour",
  "bs_tour_title_step_welcome": "Chào mừng đến với Brand Saas",
  "bs_travel": "Du lịch",
  "bs_try_it_now": "Try it now",
  "bs_twitter": "Twitter",
  "bs_unlimited": "Không giới hạn",
  "bs_update_performance": "Cập nhật hiệu suất",
  "bs_upload_image_format": "Ảnh không đúng định dạng",
  "bs_upload_image_max_size": "Vui lòng tải ảnh có dung lượng tối đa 4M",
  "bs_user_created": "User-created",
  "bs_username": "Tên Đăng Nhập",
  "bs_username_is_required": "Bắt buộc nhập \"Tên Đăng Nhập\"",
  "bs_value": "Value",
  "bs_verification_code_incorrect": "Mã xác thực không chính xác",
  "bs_vi": "Tiếng Việt",
  "bs_video": "Video",
  "bs_video_booking": "Video booking",
  "bs_video_job_create": "Create video job",
  "bs_video_job_edit": "Edit video job",
  "bs_video_job_field_required": "This field is required",
  "bs_video_job_text_buy_now": "Mua ngay",
  "bs_video_job_text_buy_video": "Mua video",
  "bs_video_job_text_check_and_download_video": "Nghiệm thu và tải xuống video để sử dụng",
  "bs_video_job_text_check_video": "Nghiệm thu video",
  "bs_video_job_text_choose_kol": "Chúc mừng bạn đã chọn được KOL cho mình",
  "bs_video_job_text_choose_kol_title": "Chọn KOL",
  "bs_video_job_text_congratulation_first_step": "Chúc mừng bạn đã hoàn thành bước đầu tiên",
  "bs_video_job_text_congratulation_video": "Chúc mừng bạn đã hoàn thành luồng đặt video cùng Passio",
  "bs_video_job_text_create_order": "Tạo đơn đặt hàng",
  "bs_video_job_text_create_order_kol_success": "Xem các KOL ứng tuyển và chọn KOL bạn muốn",
  "bs_video_job_text_create_order_success": "Bạn đã tạo thành công đơn đặt hàng đầu tiên",
  "bs_video_job_text_description_for_kol": "Mô tả cho KOL những yêu cầu về video của bạn",
  "bs_video_job_text_guide_book_video": "Hướng dẫn book video",
  "bs_video_job_text_receive_video": "Nhận video, kiểm duyệt và phản hồi nếu cần chỉnh sửa",
  "bs_video_job_text_receive_video_title": "Nhận video",
  "bs_video_job_text_start_buy_video": "Hãy bắt đầu bằng việc mua loại video bạn muốn đặt nào!",
  "bs_video_job_text_steps_to_book_video": "Các bước để book được video",
  "bs_video_live": "Video live",
  "bs_video_package_button_checkout": "Thanh toán",
  "bs_video_package_button_contact": "Liên hệ",
  "bs_video_package_text_cart": "Đơn hàng",
  "bs_video_package_text_cart_title": "Đơn hàng",
  "bs_video_package_text_company_name": "Tên công ty",
  "bs_video_package_text_contact": "Liên hệ với chúng tôi",
  "bs_video_package_text_contact_price": "Liên hệ để nhận báo giá",
  "bs_video_package_text_discount_code": "Mã giảm giá",
  "bs_video_package_text_duration": "Thời gian video tối đa",
  "bs_video_package_text_email": "Email",
  "bs_video_package_text_empty": "Hiện tại không có gói video nào",
  "bs_video_package_text_empty_cart": "Chưa có gói video nào",
  "bs_video_package_text_hotline": "Số điện thoại",
  "bs_video_package_text_payment_methods": "Thanh toán qua các cổng thanh toán",
  "bs_video_package_text_subtotal": "Tạm tính",
  "bs_video_package_text_total": "Tổng tiền",
  "bs_video_package_text_video": "Video",
  "bs_video_package_text_zalo": "Zalo",
  "bs_video_report_title": "Video report",
  "bs_video_sample_text": "Video mẫu",
  "bs_vietNam_branch": "VietNam Branch",
  "bs_vietnam": "Việt nam",
  "bs_view_content": "Xem nội dung",
  "bs_view_detail": "View Detail",
  "bs_view_on_tiktok": "Xem trên tiktok",
  "bs_view_order": "Xem đơn hàng",
  "bs_violate": "Vi Phạm",
  "bs_whole_system": "Toàn hệ thống",
  "bs_win_clients": "win clients",
  "bs_write_comment_failed": "Viết comment thất bại",
  "bs_write_comment_successfully": "Viết comment thành công",
  "bs_you_label": "Bạn",
  "bs_your_social_campaigns": "Your Social Campaigns",
  "bs_youtube": "Youtube",
  "pc_error_image_max_size": "Kích thước tối đa: {{size}}",
  "search_campaign_bookings_v2": "Tìm theo ID, tên",
  "ss_brand_booking_title_modal_warning": "Warning Detail",
  "ss_placeholder_select_sku": "Vui lòng chọn Sku",
  "ss_placeholder_select_sub_brand": "Vui lòng chọn Sub-brand",
  "ss_result_booking_text_warning_button": "Cảnh báo",
  "status_all": "Tất cả",
  "status_campagin_end": "Đã kết thúc",
  "status_campagin_not_start": "Chưa bắt đầu",
  "status_campagin_running": "Đang diễn ra",
  "status_complete": "Hoàn thành",
  "status_processing": "Đang xử lí",
  "sub_brand_report": "Sub-brand Report",
  "submit_campaign_input_channel_link": "Link Channel",
  "submit_campaign_input_channel_link_placeholder": "Nhập link channel",
  "submit_campaign_input_reach_count": "Lượt tiếp cận",
  "text_custom_range": "Tùy Chọn Khoảng Thời Gian",
  "text_last_month": "Tháng Trước",
  "text_last_n_day": "{{n}} Ngày Gần Nhất",
  "text_this_month": "Tháng Này",
  "text_today": "Hôm Nay",
  "text_yesterday": "Hôm Qua",
  "vi_orders": "Đơn hàng"
} 
} 
export default vi