export const DEFAULT_CURRENT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;

export const DEFAULT_MAX_WIDTH_SCREEN_MOBILE = 1023;

export const MIN_WIDTH_MOBILE = 375;

export const APP_PREFIX_TRANSLATION_KEY = 'bs_';

export const version = 'v1';

export const PATH_FAVICON = '/images/koc-information/avatar-passio.png';
export const DEFAULT_IMAGE = '/Image-Default-v3.png';
export const DEFAULT_LIVESTREAM_IMAGE = '/Image-Default-Livestream.png'
export const DEFAULT_IMAGE_V2 = '/Image-Default-v2.png';
export const DEFAULT_IMAGE_OLD = '/Image-Default.png';

export const ONE_THOUSAND = 1000;
export const ONE_MILLION = 1000000;
export const ONE_BILLION = 1000000000;
export const ONE_TRILLION = 1000000000000;
export type TypeDenominations = 'K' | 'M' | 'B';

export const CACHE_TIME = 60 * 60 * 1000;
export const MIN_TEXT_SEARCH = 3;
export const IMAGE_SAMPLE =
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9lcsxrF8y6syCvTXgZXwX6M1Bkdm0Q189rQ&s';
