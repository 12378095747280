import React, { useEffect, useState } from 'react';
import '@styles/globals.css';
import '@styles/custom-antd.css';
import '@styles/tailwind.css';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { ConfigProvider } from 'antd';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from '@store/index';
import i18n from '@config/i18n';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from '@components/core/ErrorPage';
import UploadShippingInfo from '@features/tiktok-affiliate/components/UploadShippingInfo';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { KEY_TOKEN } from '@constants/system';
import {
  selectUploadNotificationFileId,
  hideUploadNotification,
} from '@features/tiktok-affiliate/tiktokAffiliateSlice';

// init default language
i18n.changeLanguage('en').then();

// remove logs in production
const noop = () => {};
if (process?.env?.NODE_ENV === 'production') {
  console.log = noop;
  console.warn = noop;
}

const Hydrated = ({ children }: { children?: any }) => {
  const [hydration, setHydration] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHydration(true);
    }
  }, []);
  return hydration ? children : null;
};

function AppComponent({ Component, pageProps }: any) {
  const router = useRouter();
  const arrayRouters = router?.pathname?.split('/');
  const isRoleBrand = arrayRouters?.[1] !== 'agency' && arrayRouters?.[1] !== 'host';
  const isLogin = Cookies.get(KEY_TOKEN);
  const fileId = useSelector(selectUploadNotificationFileId) || localStorage.getItem('import_id');
  const [importId, setImportId] = useState<any>('');
  const dispatch = useDispatch();

  useEffect(() => {
    setImportId(fileId);
  }, [fileId]);

  return (
    <>
      <Component {...pageProps} />
      {isLogin && isRoleBrand && importId && (
        <UploadShippingInfo
          onClose={() => {
            dispatch(hideUploadNotification());
            localStorage.removeItem('import_id');
            localStorage.removeItem('import_shop_code');
            localStorage.removeItem('file_name_import');
          }}
        />
      )}
    </>
  );
}

export default function App({ Component, pageProps }: AppProps) {
  const [queryClient] = React.useState(() => new QueryClient());
  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <ConfigProvider
            theme={{
              token: {
                fontFamily: 'Inter',
                colorPrimary: '#ee3446',
                colorInfo: '#ee3446',
              },
            }}
          >
            <Provider store={store}>
              <Head>
                <title />
                <meta
                  key={'meta_viewport'}
                  name='viewport'
                  content='minimum-scale=1, initial-scale=1, user-scalable=0, width=device-width, shrink-to-fit=no, maximum-scale=1.0'
                />
              </Head>
              <Hydrated>
                <AppComponent Component={Component} pageProps={pageProps} />
              </Hydrated>
            </Provider>
          </ConfigProvider>
        </Hydrate>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}
