import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import {
  selectImportShopCode,
  selectUploadNotificationFileId,
  selectFileNameImport,
  selectShopCode,
} from '@features/tiktok-affiliate/tiktokAffiliateSlice';
import { IconClosePopupUpload, IconUploadFailed, IconUploadFile, IconUploadSuccess } from '../icons';
import tiktokAffiliateApis from '@features/tiktok-affiliate/tiktokAffiliateApis';
import FileSaver from 'file-saver';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

interface UploadStatusNotificationProps {
  onClose: () => void;
}

type UploadStatus = 'uploading' | 'success' | 'failed' | 'closed';

const UploadShippingInfo: React.FC<UploadStatusNotificationProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [status, setStatus] = useState<UploadStatus>('uploading');
  const [errorCount, setErrorCount] = useState<number>(0);
  const importId = useSelector(selectUploadNotificationFileId) || localStorage.getItem('import_id');
  const defaultShopCode = useSelector(selectShopCode);
  const shopCode = useSelector(selectImportShopCode) || defaultShopCode || localStorage.getItem('import_shop_code');
  const fileName = useSelector(selectFileNameImport) || localStorage.getItem('file_name_import');

  useEffect(() => {
    if (!shopCode || !importId) return;
    const interval = setInterval(async () => {
      try {
        const response = await tiktokAffiliateApis.importShippingStatus({
          shop_code: shopCode,
          import_id: importId,
        });
        if (response?.success) {
          if (response?.data?.status === 2) {
            clearInterval(interval);
            await queryClient.invalidateQueries(['getProductSuggestedApproved']);
            if (response?.data?.total_errors > 0) {
              setErrorCount(response?.data?.total_errors);
              setStatus('failed');
            } else {
              setStatus('success');
            }
          } else {
            setErrorCount(response?.data?.total_rows - response?.data?.total_processed);
            // clearInterval(interval);
          }
        } else {
          setStatus('failed');
          clearInterval(interval);
        }
      } catch {
        setStatus('failed');
        clearInterval(interval);
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [importId, shopCode]);

  const downloadErrorFileImport = async () => {
    try {
      const response = await tiktokAffiliateApis.exportErrorData({
        shop_code: shopCode,
        import_id: importId,
      });
      let blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      });
      FileSaver.saveAs(blob, `Error_shipping_info.xlsx`, { autoBom: true });
    } catch {}
  };

  return (
    <div className='fixed bottom-20 right-0 w-80 p-4 bg-white rounded-lg shadow-custom gap-3 z-50 font-helvetica text-sm'>
      {status !== 'uploading' && (
        <div className='absolute -top-2 -left-3 cursor-pointer' onClick={onClose}>
          <IconClosePopupUpload />
        </div>
      )}

      <div className='flex flex-col gap-2'>
        <h4 className='font-semibold text-[#181C32E0]'>
          {status === 'uploading' && 'Uploading'}
          {status === 'failed' && 'Upload failed'}
          {status === 'success' && 'Upload successfully'}
        </h4>
        <div className='flex justify-between gap-2'>
          <div className='flex gap-2 items-center'>
            <IconUploadFile />
            <p className='text-[#181C32] line-clamp-1'>{`${fileName?.substring(0, 30)}...` || ''}</p>
          </div>

          {status === 'uploading' && <LoadingOutlined style={{ color: 'red' }} className='animate-spin' />}
          {status === 'failed' && (
            <div className='cursor-pointer'>
              <IconUploadFailed />
            </div>
          )}
          {status === 'success' && <IconUploadSuccess />}
        </div>
        {status === 'failed' && (
          <div className='flex gap-2 items-center justify-between'>
            <p className='text-[#F1416C]'>
              {errorCount} {t('bs_label_error_record')}
            </p>
            <Button onClick={downloadErrorFileImport} className='cursor-pointer'>
              {t('bs_label_download_error_file')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadShippingInfo;
