import { IAppResponseDTO } from '@constants/responseDTO';
import { setupAxios, setupAxiosFormData } from './setupAxios';

/**
  Example get method:
      const payload: IInputDTO = {};
      const response = ApiRequests.get<IInputDTO, IOutputDTO>('url-here', payload);
**/

class ApiRequests {
  public get<I = any, O = any>(apiUrl: string, params?: I, isAuth = true) {
    const axios = setupAxios(isAuth);
    return new Promise<IAppResponseDTO<O>>((resolve, reject) => {
      axios
        .get(apiUrl, { params })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }
  public getAxios<I = any, O = any>(apiUrl: string, params?: I, isAuth = true) {
    const axios = setupAxios(isAuth);
    return new Promise<O>((resolve, reject) => {
      axios
        .get(apiUrl, { params })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  public getAxiosBlob<I = any, O = any>(apiUrl: string, params?: I, isAuth = true) {
    const axios = setupAxios(isAuth);
    return new Promise<O>((resolve, reject) => {
      axios
        .get(apiUrl, { params, responseType: 'blob' })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  public post<I = any, O = any>(apiUrl: string, data?: I, isAuth = true) {
    const axios = setupAxios(isAuth);
    return new Promise<IAppResponseDTO<O>>((resolve, reject) => {
      axios
        .post(apiUrl, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }
  public postFormData<I = any, O = any>(apiUrl: string, data?: I, isAuth = true) {
    const axios = setupAxiosFormData(isAuth);
    return new Promise<IAppResponseDTO<O>>((resolve, reject) => {
      axios
        .postForm(apiUrl, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  public put<I = any, O = any>(apiUrl: string, data?: I, isAuth = true) {
    const axios = setupAxios(isAuth);
    return new Promise<IAppResponseDTO<O>>((resolve, reject) => {
      axios
        .put(apiUrl, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  public delete<I = any, O = any>(apiUrl: string, data?: I, isAuth = true) {
    const axios = setupAxios(isAuth);
    return new Promise<IAppResponseDTO<O>>((resolve, reject) => {
      axios
        .delete(apiUrl, { data })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }
}

export const Requests = new ApiRequests();
