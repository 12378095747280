import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { InfoCampaign } from '@features/tiktok-affiliate/tiktokAffiliateInterface';

interface TiktokState {
  isConnect: boolean;
  isLoading: boolean;
  shop_code: string;
  campaign_active: InfoCampaign | undefined;
  isVisible: boolean;
  fileId: string | null | number;
  importShopCode: string | null | number;
  fileName: string | null;
}

const initialState: TiktokState = {
  isConnect: false,
  isLoading: false,
  shop_code: '',
  campaign_active: undefined,
  isVisible: false,
  fileId: '',
  importShopCode: '',
  fileName: '',
};
const TiktokAffSlice = createSlice({
  name: 'TiktokAffiliateSlice',
  initialState,
  reducers: {
    setConnect: (state: TiktokState, action: PayloadAction<boolean>) => {
      state.isConnect = action.payload;
    },
    setIsLoading: (state: TiktokState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShopCode: (state: TiktokState, action: PayloadAction<string>) => {
      state.shop_code = action.payload;
    },
    setCampaignActive: (state: TiktokState, action: PayloadAction<InfoCampaign | undefined>) => {
      state.campaign_active = action.payload;
    },
    setImportShopCode: (state: TiktokState, action: PayloadAction<string>) => {
      state.importShopCode = action.payload;
    },
    setFileNameImport: (state: TiktokState, action: PayloadAction<string>) => {
      state.fileName = action.payload;
    },
    showUploadNotification: (state: TiktokState, action: PayloadAction<string>) => {
      state.isVisible = true;
      state.fileId = action.payload;
    },
    hideUploadNotification: (state: TiktokState) => {
      state.isVisible = false;
      state.fileId = null;
    },
  },
});
export const {
  setConnect,
  setIsLoading,
  setShopCode,
  setCampaignActive,
  showUploadNotification,
  hideUploadNotification,
  setImportShopCode,
  setFileNameImport,
} = TiktokAffSlice.actions;
export const selectConnectTiktok = (state: RootState) => state.tiktokAffiliate.isConnect;
export const selectIsLoadingConnect = (state: RootState) => state.tiktokAffiliate.isLoading;
export const selectShopCode = (state: RootState) => state.tiktokAffiliate.shop_code;
export const selectCampaign = (state: RootState) => state.tiktokAffiliate.campaign_active;
export const selectUploadNotificationVisibility = (state: RootState) => state.tiktokAffiliate.isVisible;
export const selectUploadNotificationFileId = (state: RootState) => state.tiktokAffiliate.fileId;
export const selectImportShopCode = (state: RootState) => state.tiktokAffiliate.importShopCode;
export const selectFileNameImport = (state: RootState) => state.tiktokAffiliate.fileName;
const TiktokAffReducer = TiktokAffSlice.reducer;
export default TiktokAffReducer;
